import { Subject } from "rxjs";
import { PeerConnectionState } from "./state.model";

export enum MessageType {
  init = 'init',
  offer = 'offer',
  answer = 'answer',
  candidate = 'candidate',
}

export interface DescriptionResult {
  success: boolean;
  description?: RTCSessionDescription | null;
}

export interface IMediaStreamTrackInfo {
  streamId: number,
  track: MediaStreamTrack;
}
export interface IMediaStreamInfo {
  instanceId: string;
  streamId: number,
  mediaStream: MediaStream;
}

export interface PeerConnectionInfo {
  streamIds: number[]
  peerConnection: RTCPeerConnection;
  state$: Subject<PeerConnectionState>
}

export enum PeerConnectionType {
  dataChannel = 'dataChannel',
  mediaBroadcast = 'mediaBroadcast'
}

export interface GroupCameraByUrl {
  url: string;
  cameras: number[]
}