import { PermissionsTreeKey, PermissionsTreeKeysType, PermissionsTreeRules } from './models';
import { PermissionsTreeBase } from './permissions-tree-base';

export class PermissionsTreeEngineerGates extends PermissionsTreeBase {
    constructor() {
        const defaultRoute = '/dashboard';

        const keys: PermissionsTreeKeysType = {
            [PermissionsTreeKey.DASHBOARD]: true,
            [PermissionsTreeKey.ABONENTS_SIGN_UPS]: false
        };

        const routes: PermissionsTreeRules = {
            dashboard: { enabled: true },
            pbx: { enabled: false },
            entrances: {
                enabled: false,
                childrens: {
                    id: { enabled: false }
                }
            },
          'personal_surveillance': {
            enabled: false,
            childrens: {
              id: { enabled: false }
            }
          },
            intercom: {
                childrens: {
                    adapters: { enabled: false },
                    cameras: { enabled: false },
                    'new-adapters': { enabled: false },
                    batches: { enabled: false },
                    shields: {
                        enabled: false,
                        childrens: {
                            shield: { enabled: false },
                        }
                    },
                    models: { enabled: false },
                    orders: { enabled: false },
                }
            },
            abonents: {
                childrens: {
                    subscribers: {
                        enabled: false,
                        childrens: {
                            subscriber: { enabled: false },
                        }
                    },
                    sign_ups: { enabled: false },
                }
            },
            mailing: {
                childrens: {
                    all: { enabled: false },
                    new: { enabled: false },
                    id: { enabled: false },
                }
            },
            services: {
                enabled: false,
                childrens: {
                    'video-surveillance': {
                        enabled: false,
                        childrens: {
                            id: { enabled: false }
                        }
                    },
                  'video-manager': {
                    enabled: false,
                    childrens: {
                      add: { enabled: false },
                      edit: { enabled: false },
                      show: { enabled: false }
                    }
                  },
                    gates: {
                        enabled: true,
                        childrens: {
                            id: { enabled: true }
                        }
                    },
                    'software-intercoms': {
                        enabled: false,
                        childrens: {
                            id: { enabled: false }
                        }
                    }
                }
            },
            payments: { enabled: false },
            login: { enabled: true },
            support: { enabled: false },
            integrations: {
                enabled: false,
                childrens: {
                    monitoring: {enabled: false}
                }
              }
        };

        super(defaultRoute, keys, routes);
    }
}
