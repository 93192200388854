<div
  class="component-loader"
  [class.component-loader--background-enabled]="background"
  [style.border-radius]="borderRadiusPx + 'px'"
>
  <div
    class="component-loader-wrapper"
    [class.component-loader-wrapper--absolute]="overTheComponent"
  >
    <div
      class="component-loader-wrapper-spinner"
      [style.width]="sizePx + 'px'"
      [style.height]="sizePx + 'px'"
    >
      <mat-progress-spinner
        [color]="whiteCircle ? 'white' : 'primary'"
        [attr.ng-reflect-color]="whiteCircle ? 'white' : 'primary'"
        [mode]="'indeterminate'"
        [value]="50"
        [diameter]="sizePx"
      ></mat-progress-spinner>
    </div>
  </div>
</div>
