import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  GetVideoshotPopupPlaylists,
  HlsFragParseHelper,
  MAX_FATALS_COUNT,
  RdeaHls,
  TZ_OFFSET_IN_MILLISECONDS,
  TZ_OFFSET_IN_MINUTES,
  VideoPlayerChangeVideoshotState,
  VideoPlayerErrorTypes,
  VideoPlayerFacade,
  VideoPlayerFragmentShortDate,
  VideoPlayerHlsError,
  VideoPlayerState,
  VideoPlayerVideoshotPopupComponent,
  VideoPlayerVideoshotRequest,
  VideoshotPopupState
} from '@app/shared/components/video-player';
import { catchError, delay, map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { DialogWrapperData } from '@app/shared/ui';
import { Constants, parseError } from '@app/shared/helpers';
import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@app/shared/components';
import { isEmpty } from 'lodash';
import { RdeaDate } from '@app/shared/entities';
import Hls, { ErrorTypes, Fragment } from 'hls.js';
import { AuthFacade } from '@app/views';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { CameraApiService, ICamerasRdaConfig, RdaApiService } from '@app/shared/entities/rd';
import { VideoManagerN } from '@app/views/services/submodules/video-manager/models/view-manager';
import ScreenItem = VideoManagerN.ScreenItem;
import { DecorateUntilDestroy, takeUntilDestroyed } from '@app/shared/rxjs/operator/take-until-destroyed';
import { Store } from '@ngrx/store';
import { WebRTCExperimentHelpers } from '@app/shared/components/video-player/helpers/webrtc-expriment-helpers';
import { DatePipe } from '@angular/common';
import { Option } from '@app/shared/video/models/select.model';
import { HlsManifestItems } from '@app/shared/video/models/hls.model';
import { HlsHelper } from '@app/shared/video/helpers/hls-helper';
import { VideoShotData } from '@app/shared/video/models/video.models';

@DecorateUntilDestroy()
@Component({
  selector: 'app-video-manager-player',
  templateUrl: './video-manager-player.component.html',
  styleUrls: ['./video-manager-player.component.scss'],
  providers: [VideoPlayerFacade],
  encapsulation: ViewEncapsulation.None
})
export class VideoManagerPlayerComponent implements OnInit, AfterViewChecked, OnDestroy {
  protected readonly isEmpty = isEmpty;

  @Input() public width: string;
  @Input() public height: string;
  @Input() public token: string;
  @Input() public hideSmallActions = false;
  @Input() public disablePlyrAutoHide = false;
  @Input() public depthInHours: number;
  @Input() public camera: ScreenItem;

  @Output() clickVideoWrapperEvent = new EventEmitter();

  @ViewChild('video') public videoElement: ElementRef<HTMLVideoElement>;
  @ViewChild('playerWrapperElement') public playerWrapperElement: ElementRef<HTMLDivElement>;

  public playlists: MenuItem[] = [];
  public eventsList: MenuItem[] = [];
  public archivePlaylist: { playlist: string; label: string; } = null;
  public selectedEvent: { label: string; id: string; item: VideoPlayerFragmentShortDate } = null;
  public loaded = false;

  public smallScreenActions: MenuItem[] = [];

  public errorLoadingPlaylist = false;
  public errorLoadingVideo = false;

  public get isSmallSize(): boolean {
    return this.playerWrapperElement?.nativeElement?.offsetWidth <= 550;
  }

  public get rdvaURI(): string {
    return this.camera?.camera?.rdva?.uri;
  }

  public get cameraId(): number {
    return this.camera?.camera?.id;
  }

  public get isCanChangeToPrevEvent(): boolean {
    const currentEventIndex = this.eventsList.findIndex((e) => e.id === this.selectedEvent?.id);
    return !isEmpty(this.eventsList) && this.eventsList[0].id !== null && currentEventIndex > 0;
  }

  public get isCanChangeToNextEvent(): boolean {
    const currentEventIndex = this.eventsList.findIndex((e) => e.id === this.selectedEvent?.id);
    return !isEmpty(this.eventsList) && this.eventsList[0].id !== null && currentEventIndex < this.eventsList.length - 1;
  }

  public get playlistArchiveAction(): MenuItem {
    return {
      label: this.archivePlaylist?.label ? this.archivePlaylist.label : this.translate.instant('abonent.page.info.header.events'),
      icon: 'pi pi-calendar',
      items: this.playlists,
    };
  }

  public get eventsAction(): MenuItem {
    return {
      label: this.selectedEvent?.label ? this.selectedEvent.label : this.translate.instant('abonent.page.info.header.events'),
      icon: 'pi pi-bell',
      items: this.eventsList,
    };
  }
  
  public get componentId() : string {
    return `${this.camera.cameraId}-${this.camera.index}`
  }
  

  @ViewChild('actionsSmall') private actionsSmall: ElementRef<HTMLDivElement>;
  @ViewChild('actionsLarge') private actionsLarge: ElementRef<HTMLDivElement>;

  private releInfo: ICamerasRdaConfig = null;
  private recoverErrorTries = 0;
  private activeStreamPlaylist: string;
  private destroy$ = new Subject<void>();
  private isPopupReadyToShow$: Subject<boolean> = new BehaviorSubject<boolean>(false);
  private videoshotPopupData$: Observable<string[]> = this.videoPlayerFacade.videoshotPlaylistData$;

  public get isCanOpenDoor(): boolean {
    return this.releInfo !== null;
  }

  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    private resolution: ResolutionService,
    private snackbar: SnackbarService,
    private router: Router,
    private authFacade: AuthFacade,
    private rdaApiService: RdaApiService,
    private cameraApiService: CameraApiService,
    protected videoPlayerFacade: VideoPlayerFacade,
    private store: Store<VideoPlayerState>,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.cameraApiService.getCameraRDAConfigById(this.cameraId)
      .pipe(
        catchError((error: HttpErrorResponse) => of(error)),
        takeUntilDestroyed(this),
      ).subscribe((res) => {
        if (res instanceof HttpErrorResponse) {
          console.warn('The door does not have a config');
        } else {
          if (res.releId !== null && res.uid !== null) {
            this.releInfo = res;
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.videoPlayerFacade.clearState();
  }

  ngAfterViewChecked(): void {
    const newActions = [
      { separator: true },
      { separator: true },
      this.playlistArchiveAction,
      this.eventsAction,
      { separator: true },
    ].filter((a) => a);
    if (this.smallScreenActions?.length !== newActions.length) {
      this.smallScreenActions = newActions;
    }
  }

  clickVideoWrapper(): void {
    this.clickVideoWrapperEvent.emit()
  }

  openVideoShotPopup(items: HlsManifestItems): void {
    const videoShotRangeData = HlsHelper.getVideoShotRangeData(items, this.cameraId);

    if (!videoShotRangeData) {
      return;
    }

    const data: DialogWrapperData<VideoPlayerVideoshotRequest, null> = {
      title: this.translate.instant('shared.video_player.video_shot.title'),
      componentName: 'GetVideoshot',
      body: {
        minDate: videoShotRangeData.minDate,
        maxDate: videoShotRangeData.maxDate,
        depthInHours: this.depthInHours,
        rdvaUri: this.rdvaURI,
        cameraId: this.cameraId,
        token: this.token,
        isMobile: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN)
      }
    };

    this.dialog.open(VideoPlayerVideoshotPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      autoFocus: false,
      data
    });
  }

  openDoor(): void {
    this.rdaApiService.openDoor(this.releInfo?.uid, this.releInfo?.releId).pipe(
      catchError((error: HttpErrorResponse) => of(error)),
      take(1)
    ).subscribe((response) => {
      if (response instanceof HttpErrorResponse) {
        this.snackbar.showMessage(
          this.translate.instant('shared.video_player.message.open.failed.text', {
            text: response.message
          }),
          'error'
        );
        return;
      }
      this.snackbar.showMessage(
        this.translate.instant('shared.video_player.message.open.success'),
        'success'
      );
    });
  }

  changeEvent(next: boolean): void {
    const currentEventIndex = this.eventsList.findIndex((e) => e.id === this.selectedEvent?.id);
    this.eventsList[next ? (currentEventIndex + 1) : (currentEventIndex - 1)].command();
  }

  onRequestFullScreen(value): void {
    if (value) {
      this.playerWrapperElement.nativeElement.requestFullscreen();
    } 

    if(!value && document.fullscreenElement){
      document.exitFullscreen();
    }
  }

  private getDoorKeys(): Observable<ICamerasRdaConfig | null> {
    return this.cameraApiService.getCameraRDAConfigById(this.cameraId)
      .pipe(
        catchError((error: HttpErrorResponse) => of(null)),
        takeUntil(this.destroy$),
      );
  }

  private showFatalErrorMessage(errorType: string, details: string): void {
    let reason: string;
    switch (errorType) {
      case Hls.ErrorTypes.NETWORK_ERROR:
        reason = details === 'levelEmptyError'
          ? this.translate.instant('shared.video_player.message.error.reason.empty')
          : this.translate.instant('shared.video_player.message.error.reason.error')
          ;
        break;
      case Hls.ErrorTypes.MEDIA_ERROR:
        reason = this.translate.instant('shared.video_player.message.error.reason.media');
        break;
      default:
        reason = this.translate.instant('shared.video_player.message.error.reason.unknown');
    }
    this.snackbar.showMessage(
      this.translate.instant('shared.video_player.message.error.reason.unknown', {
        text: reason
      })
    );
  }

  private formatDate(timestamp: number, format: string = 'dd MMMM'): string {
    return this.datePipe.transform(timestamp - TZ_OFFSET_IN_MILLISECONDS, format);
  }
}
