import { Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from "rxjs";
import { HlsInfo, HlsManifestItems, TypeReceiver } from "../models/hls.model";
import { RdeaHls, TZ_OFFSET_IN_MINUTES } from "@app/shared/components/video-player";
import { LocalStorageGeneralKey, LocalStorageHelper, LoggerService } from "@app/shared/entities";
import { MediaService } from "../media.service/media.service";
import { IVideoConfigHls } from "../models/video.models";
import { catchError, filter, mergeMap, switchMap, take, tap, withLatestFrom } from "rxjs/operators";
import { HlsEventsService } from "./hls-events.service";
import { takeUntilDestroyed } from "@app/shared/rxjs/operator/take-until-destroyed";
import { RdvaApiService, RdvaPlaylistItem } from "@app/shared/entities/integrations";
import { HttpErrorResponse } from "@angular/common/http";
import { StoreRegistryService } from "../store/store-registry.service";
import { StoreService } from "../store/store.service";
import { map } from "jquery";

@Injectable({ providedIn: 'root' })
export class HlsConnectionService {
  private hlsInfoListSubject = new BehaviorSubject<HlsInfo[]>([]);
  hlsInfoList$ = this.hlsInfoListSubject.asObservable();

  constructor(
    private mediaService: MediaService,
    private storeRegistryService: StoreRegistryService,
    private loggerService: LoggerService,
    private hlsEventsService: HlsEventsService,
    private rdvaApiService: RdvaApiService
  ) { }

  addReceiver(config: IVideoConfigHls): void {
    if (config.type === TypeReceiver.LIVE) {
      const hls = this.buildHls()
      this.addHlsInfo(hls, config.instanceId)

      this.hlsEventsService.enableDataErrorEventsListener(hls, config.instanceId);
      this.addMedia(config.type, config.instanceId, config.streamId, hls)
      this.loadPlaylists(hls, config.hostUri, config.streamId);
    }

    if (config.type === TypeReceiver.ARCHIVE) {

      const hls = this.buildHls()
      this.addHlsInfo(hls, config.instanceId)
      this.addMedia('hls-archive', config.instanceId, config.streamId, hls)

      this.subscribePlaylistItems(config.instanceId)
      this.subscribeGetArchivePlaylist(config.hostUri, config.streamId, config.instanceId);
      this.subscribeChangeArchivePlaylist(config.streamId, config.instanceId);
    }
  }

  destroyHls(instanceId: string): void {
    this.storeRegistryService.getStore(instanceId)
      .updatePlayListItems([])

    const hlsInfo = this.findHlsInfo(instanceId);

    if (hlsInfo) {
      hlsInfo.hls.destroy();
      this.mediaService.removeMedia(instanceId);

      const filteredHlsInfoList = this.filterHlsInfo(instanceId);
      this.hlsInfoListSubject.next(filteredHlsInfoList);
    }
  }

  destroy(): void {
    this.hlsInfoListSubject.value
      .forEach(value => value.hls.destroy());
    this.hlsInfoListSubject.next([]);
  }

  private addMedia(type: string, instanceId: string, streamId: number, hls: RdeaHls): void {
    this.mediaService.addMedia({
      instanceId,
      type,
      streamId,
      connectMedia: (element: HTMLVideoElement): void => {
        hls.attachMedia(element)
      },
      destroyMedia: (instanceId: string): void => {
        this.mediaService.removeMedia(instanceId)
      }
    })
  }

  private restartHlsForPlaylist(instanceId: string, streamId: number, playlist: string): void {
    const videoRef = this.storeRegistryService.getStore(instanceId).getVideoElementRef()

    const hlsInfo = this.findHlsInfo(instanceId);
    if (!hlsInfo || !videoRef) {
      return;
    }

    if(!hlsInfo.hls['url']){
      this.loadSource(hlsInfo.hls, playlist)
    } else {
      hlsInfo.hls.detachMedia()
      this.loadSource(hlsInfo.hls, playlist)
    }

    hlsInfo.hls.attachMedia(videoRef)

    this.hlsEventsService.initManifestLoadedDataListener(hlsInfo.hls, instanceId)

    if (this.loggerService.enabled) {
      this.hlsEventsService.enableDebugEventsListeners(hlsInfo.hls);
    }
  }

  private addHlsInfo(hls: RdeaHls, id: string): void {
    const hlsInfo: HlsInfo = {
      id,
      hls
    }

    const currentList = this.hlsInfoListSubject.value;
    this.hlsInfoListSubject.next([...currentList, hlsInfo])
  }

  private findHlsInfo(id: string): HlsInfo {
    return this.hlsInfoListSubject.value
      .find(value => value.id == id)
  }

  private filterHlsInfo(id: string): HlsInfo[] {
    return this.hlsInfoListSubject.value
      .filter(value => value.id !== id);
  }

  private buildHls(): RdeaHls {
    const token = LocalStorageHelper.getItem(LocalStorageGeneralKey.AUTH_TOKEN);

    return new RdeaHls(token, {
      autoStartLoad: true,
      maxBufferHole: 10,
      liveDurationInfinity: true,
      startFragPrefetch: true,
      manifestLoadingTimeOut: 10000,
      manifestLoadingMaxRetry: 5,
    });
  }

  private loadPlaylists(hls: RdeaHls, hostUri: string, streamId: number) {
    const source = this.getHlsLink(hostUri, streamId)
    this.loadSource(hls, source)
  }

  private getHlsLink(hostUri: string, id: number): string {
    return `https://s.${hostUri}/live/${id}.m3u8`;
  }

  private getPlaylistEdges$(playlistItems: RdvaPlaylistItem[]): Observable<HlsManifestItems> {
    const [firstPlaylist, lastPlaylist] = [playlistItems[0].playlist, playlistItems[playlistItems.length - 1].playlist];

    const firstPlaylistObservable = this.rdvaApiService.getPlaylist(firstPlaylist)
    const lastPlaylistObservable = this.rdvaApiService.getPlaylist(lastPlaylist)

    return forkJoin([firstPlaylistObservable, lastPlaylistObservable])
  }

  private getPlaylistItems$(hostUri: string, streamId: number, storeId: string): Observable<[StoreService, RdvaPlaylistItem[]]> {
    return combineLatest([
      this.storeRegistryService.selectStore(storeId),
      this.rdvaApiService.getPlaylists(hostUri, streamId, -TZ_OFFSET_IN_MINUTES)
    ])
  }

  private subscribeGetArchivePlaylist(hostUri: string, streamId: number, storeId: string): void {
    this.getPlaylistItems$(hostUri, streamId, storeId)
      .pipe(takeUntilDestroyed(this, { destroyMethod: this.destroyHls }))
      .subscribe({
        next: ([store, rdvaPlaylistItems]) => {
          store.updatePlayListItems(rdvaPlaylistItems)
        }
      })

    this.getManifestItems$(hostUri, streamId, storeId)
      .pipe(takeUntilDestroyed(this, { destroyMethod: this.destroyHls }))
      .subscribe(([store, manifestItems]) => {
        store.updatePlaylistHlsManifestItems(manifestItems)
      })
  }

  private subscribeChangeArchivePlaylist(streamId: number, storeId: string): void {
    this.storeRegistryService.selectStore(storeId)
      .pipe(
        mergeMap(store => {
          return store.selectCurrentPlaylist$()
        }),
        takeUntilDestroyed(this, { destroyMethod: this.destroyHls })
      )
      .subscribe({
        next: (value) => {
          this.restartHlsForPlaylist(storeId, streamId, value.playlist)
        }
      })
  }

  private subscribePlaylistItems(instanceId: string): void {
    this.storeRegistryService.selectStore(instanceId)
      .pipe(
        mergeMap(store => combineLatest([
          of(store),
          store.selectPlayListItems$()
        ])),
        takeUntilDestroyed(this, { destroyMethod: this.destroyHls })
      )
      .subscribe({
        next: ([store, playListItems]) => {
          const defaultPlaylistItem = playListItems[playListItems.length - 1]
          store.updateCurrentPlaylist(defaultPlaylistItem)
        }
      })
  }

  private getManifestItems$(hostUri: string, streamId: number, storeId: string): Observable<[StoreService, HlsManifestItems]> {
    return this.storeRegistryService.selectStore(storeId)
      .pipe(
        mergeMap(store => combineLatest([of(store), store.selectPlayListItems$()])),
        filter(([_, rdvaPlaylistItems]) => !!rdvaPlaylistItems.length),
        mergeMap(([store, rdvaPlaylistItems]) => combineLatest([of(store), this.getPlaylistEdges$(rdvaPlaylistItems)])),

        catchError((err: HttpErrorResponse) => {
          this.storeRegistryService.getStore(storeId).fireHlsPlaylistLoadError(err)
          return of(null);
        }),
      )
  }

  private loadSource(hls: RdeaHls, source: string): void {
    hls.loadSource(source)
  }
}
