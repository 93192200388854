import { Injectable } from "@angular/core";
import { HlsFragParseHelper, RdeaHls } from "@app/shared/components/video-player";
import { LoggerService } from "@app/shared/entities";
import { MediaService } from "../media.service/media.service";
import { IVideoConfigHls } from "../models/video.models";
import { take } from "rxjs/operators";
import { BufferAppendedData, BufferAppendingData, BufferCodecsData, BufferCreatedData, BufferEOSData, BufferFlushedData, BufferFlushingData, ErrorData, ErrorTypes, Events, FragBufferedData, FragChangedData, FragDecryptedData, FragLoadedData, FragLoadEmergencyAbortedData, FragLoadingData, Fragment, FragParsedData, FragParsingInitSegmentData, FragParsingMetadataData, FragParsingUserdataData, LevelLoadedData, LevelLoadingData, LevelPTSUpdatedData, LevelSwitchedData, LevelSwitchingData, LevelUpdatedData, ManifestLoadedData, ManifestLoadingData, ManifestParsedData, MediaAttachingData, SubtitleFragProcessedData, SubtitleTracksUpdatedData, SubtitleTrackSwitchData, TrackLoadedData, TrackLoadingData } from 'hls.js';
import { StoreRegistryService } from "../store/store-registry.service";

@Injectable({ providedIn: 'root' })
export class HlsEventsService {
  constructor(
    private storeRegistryService: StoreRegistryService,
    private loggerService: LoggerService
  ) { }

  destroy(): void { }

  initManifestLoadedDataListener(hls: RdeaHls, instanceId: string): void {
    hls.on(Events.MANIFEST_LOADED, (event: Events.MANIFEST_LOADED, data: ManifestLoadedData) => {
      const store = this.storeRegistryService.getStore(instanceId)

      store.updateManifestLoadedData(data);

      const fragments = data.levels[0].details.fragments;
      const { rangeTimestamps, eventTimestamps } = HlsFragParseHelper.extractDateRangesFromFragments(fragments);

      store.updateTimelineData({
        date: store.getCurrentPlaylist().date,
        mediaPeriodItems: rangeTimestamps,
        eventTimestamps
      })
    });
  }

  enableDebugEventsListeners(hls: RdeaHls) {
    hls.on(Events.MEDIA_ATTACHED, () => {
      this.loggerService.log('HLS MEDIA_ATTACHED: media attached');
    });

    hls.on(Events.MANIFEST_PARSED, (event: Events.MANIFEST_PARSED, data: ManifestParsedData) => {
      this.loggerService.log(`HLS MANIFEST_PARSED: found ${data.levels.length} quality level`);
    });

    hls.on(Events.LEVEL_SWITCHING, (event: Events.LEVEL_SWITCHING, data: LevelSwitchingData) => {
      this.loggerService.log(`HLS LEVEL_SWITCHING`, event, data);
    });

    hls.on(Events.FRAG_PARSED, (event: Events.FRAG_PARSED, data: FragParsedData) => {
      this.loggerService.log(`HLS FRAG_PARSED`, event, data);
    });

    hls.on(Events.MEDIA_ATTACHING, (event: Events.MEDIA_ATTACHING, data: MediaAttachingData) => {
      this.loggerService.log(`HLS MEDIA_ATTACHING`, event, data);
    });

    hls.on(Events.BUFFER_RESET, (event: Events.BUFFER_RESET) => {
      this.loggerService.log(`HLS BUFFER_RESET`, event);
    });

    hls.on(Events.BUFFER_CODECS, (event: Events.BUFFER_CODECS, data: BufferCodecsData) => {
      this.loggerService.log(`HLS BUFFER_CODECS`, event, data);
    });

    hls.on(Events.BUFFER_CREATED, (event: Events.BUFFER_CREATED, data: BufferCreatedData) => {
      this.loggerService.log(`HLS BUFFER_CODECS`, event, data);
    });

    hls.on(Events.BUFFER_APPENDING, (event: Events.BUFFER_APPENDING, data: BufferAppendingData) => {
      this.loggerService.log(`HLS BUFFER_APPENDING`, event, data);
    });

    hls.on(Events.BUFFER_APPENDED, (event: Events.BUFFER_APPENDED, data: BufferAppendedData) => {
      this.loggerService.log(`HLS BUFFER_APPENDED`, event, data);
    });

    hls.on(Events.BUFFER_EOS, (event: Events.BUFFER_EOS, data: BufferEOSData) => {
      this.loggerService.log(`HLS BUFFER_EOS`, event, data);
    });

    hls.on(Events.BUFFER_FLUSHING, (event: Events.BUFFER_FLUSHING, data: BufferFlushingData) => {
      this.loggerService.log(`HLS BUFFER_FLUSHING`, event, data);
    });

    hls.on(Events.BUFFER_FLUSHED, (event: Events.BUFFER_FLUSHED, data: BufferFlushedData) => {
      this.loggerService.log(`HLS BUFFER_FLUSHED`, event, data);
    });

    hls.on(Events.MANIFEST_LOADING, (event: Events.MANIFEST_LOADING, data: ManifestLoadingData) => {
      this.loggerService.log(`HLS MANIFEST_LOADING`, event, data);
    });

    hls.on(Events.LEVEL_LOADING, (event: Events.LEVEL_LOADING, data: LevelLoadingData) => {
      this.loggerService.log(`HLS LEVEL_LOADING`, event, data);
    });

    hls.on(Events.LEVEL_LOADED, (event: Events.LEVEL_LOADED, data: LevelLoadedData) => {
      this.loggerService.log(`HLS LEVEL_LOADED`, event, data);
    });

    hls.on(Events.LEVEL_UPDATED, (event: Events.LEVEL_UPDATED, data: LevelUpdatedData) => {
      this.loggerService.log(`HLS LEVEL_UPDATED`, event, data);
    });

    hls.on(Events.LEVEL_PTS_UPDATED, (event: Events.LEVEL_PTS_UPDATED, data: LevelPTSUpdatedData) => {
      this.loggerService.log(`HLS LEVEL_PTS_UPDATED`, event, data);
    });

    hls.on(Events.LEVEL_SWITCHED, (event: Events.LEVEL_SWITCHED, data: LevelSwitchedData) => {
      this.loggerService.log(`HLS LEVEL_SWITCHED`, event, data);
    });

    hls.on(Events.SUBTITLE_TRACKS_UPDATED, (event: Events.SUBTITLE_TRACKS_UPDATED, data: SubtitleTracksUpdatedData) => {
      this.loggerService.log(`HLS SUBTITLE_TRACKS_UPDATED`, event, data);
    });

    hls.on(Events.SUBTITLE_TRACK_SWITCH, (event: Events.SUBTITLE_TRACK_SWITCH, data: SubtitleTrackSwitchData) => {
      this.loggerService.log(`HLS SUBTITLE_TRACK_SWITCH`, event, data);
    });

    hls.on(Events.SUBTITLE_TRACK_LOADING, (event: Events.SUBTITLE_TRACK_LOADING, data: TrackLoadingData) => {
      this.loggerService.log(`HLS SUBTITLE_TRACK_LOADING`, event, data);
    });

    hls.on(Events.SUBTITLE_TRACK_LOADED, (event: Events.SUBTITLE_TRACK_LOADED, data: TrackLoadedData) => {
      this.loggerService.log(`HLS SUBTITLE_TRACK_LOADED`, event, data);
    });

    hls.on(Events.SUBTITLE_FRAG_PROCESSED, (event: Events.SUBTITLE_FRAG_PROCESSED, data: SubtitleFragProcessedData) => {
      this.loggerService.log(`HLS SUBTITLE_FRAG_PROCESSED`, event, data);
    });

    hls.on(Events.FRAG_LOADING, (event: Events.FRAG_LOADING, data: FragLoadingData) => {
      this.loggerService.log(`HLS FRAG_LOADING`, event, data);
    });

    hls.on(Events.FRAG_LOADED, (event: Events.FRAG_LOADED, data: FragLoadedData) => {
      this.loggerService.log(`HLS FRAG_LOADED`, event, data);
    });

    hls.on(Events.FRAG_LOAD_EMERGENCY_ABORTED, (event: Events.FRAG_LOAD_EMERGENCY_ABORTED, data: FragLoadEmergencyAbortedData) => {
      this.loggerService.log(`HLS FRAG_LOAD_EMERGENCY_ABORTED`, event, data);
    });

    hls.on(Events.FRAG_DECRYPTED, (event: Events.FRAG_DECRYPTED, data: FragDecryptedData) => {
      this.loggerService.log(`HLS FRAG_DECRYPTED`, event, data);
    });

    hls.on(Events.FRAG_PARSING_INIT_SEGMENT, (event: Events.FRAG_PARSING_INIT_SEGMENT, data: FragParsingInitSegmentData) => {
      this.loggerService.log(`HLS FRAG_PARSING_INIT_SEGMENT`, event, data);
    });

    hls.on(Events.FRAG_PARSING_USERDATA, (event: Events.FRAG_PARSING_USERDATA, data: FragParsingUserdataData) => {
      this.loggerService.log(`HLS FRAG_PARSING_USERDATA`, event, data);
    });

    hls.on(Events.FRAG_PARSING_METADATA, (event: Events.FRAG_PARSING_METADATA, data: FragParsingMetadataData) => {
      this.loggerService.log(`HLS FRAG_PARSING_METADATA`, event, data);
    });

    hls.on(Events.FRAG_BUFFERED, (event: Events.FRAG_BUFFERED, data: FragBufferedData) => {
      this.loggerService.log(`HLS FRAG_BUFFERED`, event, data);
    });

    hls.on(Events.FRAG_CHANGED, (event: Events.FRAG_CHANGED, data: FragChangedData) => {
      this.loggerService.log(`HLS FRAG_CHANGED`, event, data);
    });
  }

  enableDataErrorEventsListener(hls: RdeaHls, id: string) {
    hls.on(Events.ERROR, (event: Events.ERROR, data: ErrorData) => {
      this.loggerService.error(`HLS ERROR`, event, data);
      this.storeRegistryService.getStore(id).fireHlsConnectionErrorType(data);

      switch (data.type) {
        case ErrorTypes.MEDIA_ERROR:
          if (hls.recoverableError(data)) {
            hls.recoverMediaError();
          }
          break;
        case ErrorTypes.NETWORK_ERROR:
          hls.startLoad();
          break;
      }
    });
  }

}
