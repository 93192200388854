import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { TZ_OFFSET_IN_MILLISECONDS } from '@app/shared/components/video-player';
import { DecorateUntilDestroy, takeUntilDestroyed } from '@app/shared/rxjs/operator/take-until-destroyed';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, startWith, withLatestFrom } from 'rxjs/operators';
import { Converter } from '../../helpers/converter';
import { TimelineData } from '../../models/timeline.model';
import { MediaPeriodService } from '../../service/media-period.service';
import { StoreService } from '../../store/store.service';

@DecorateUntilDestroy()
@Component({
  selector: 'rd-video-timeline',
  templateUrl: './timeline.html',
  styleUrls: ['./timeline.scss']
})
export class RdVideoTimelineComponent implements AfterViewInit {
  @ViewChild('timeline') timelineElementRef: ElementRef<HTMLDivElement>;

  selectedDateTime$ = this.storeService.onSelectedDateTime$()
    .pipe(
      startWith(new Date(0, 0))
    )

  isTimelineEnter: boolean = false;

  constructor(
    private storeService: StoreService,
    private mediaPeriodService: MediaPeriodService,
    private datePipe: DatePipe
  ) { }

  ngAfterViewInit(): void {
    const element = this.timelineElementRef.nativeElement
    this.storeService.updateTimelineElement(element)

    this.storeService.selectTimelineData$()
      .pipe(
        takeUntilDestroyed(this)
      )
      .subscribe(() => {
        this.init();
      })
  }

  init(): void {
    this.storeService.selectSliderPosition$()
      .pipe(
        withLatestFrom(this.storeService.selectTimelineData$()),
        takeUntilDestroyed(this)
      )
      .subscribe(([position, timelineData]) => {
        const { startTime, endTime } = this.mediaPeriodService.getRangeDateTimeline(timelineData.date, timelineData.mediaPeriodItems[0].startTimestamp)
        const timestamp = Converter.percentageToTimestamp(position, startTime.getTime(), endTime.getTime())

        this.storeService.fireSelectedDateTime(timestamp);

        if (this.storeService.getSliderIsDragging()) {
          this.storeService.updateTooltipPosition(position);
          this.storeService.updateTooltipLabel(this.formatDate(timestamp, 'HH:mm:ss'));
        }
      })

    this.storeService.selectPlayerCurrentTime$()
      .pipe(
        withLatestFrom(this.storeService.selectTimelineData$()),
        takeUntilDestroyed(this)
      )
      .subscribe(([value, timelineData]) => {
        const { startTime, endTime } = this.mediaPeriodService.getRangeDateTimeline(timelineData.date, timelineData.mediaPeriodItems[0].startTimestamp)
        const timestamp = this.mediaPeriodService.convertTimeToTimestamp(value, timelineData.mediaPeriodItems);
        const positionPercentage = Converter.timestampToPercentage(timestamp - TZ_OFFSET_IN_MILLISECONDS, startTime.getTime(), endTime.getTime());

        if (!this.storeService.getSliderIsDragging()) {
          this.storeService.updateSliderPosition(positionPercentage);
        }
      })

    this.storeService.selectSliderIsDragging$()
      .pipe(takeUntilDestroyed(this))
      .subscribe(() => {
        this.storeService.updateTooltipIsHidden(true)
      })
  }

  formatDate(timestamp: number, format: string = 'dd MMMM'): string {
    return this.datePipe.transform(timestamp, format);
  }

  onTimelineMove(event: MouseEvent): void {
    const timelineData = this.storeService.getTimelineData()
    if (!timelineData) {
      return;
    }

    const offsetX = this.getMouseOffsetTimeline(event);
    const timelineRect = this.timelineElementRef.nativeElement.getBoundingClientRect();

    const percentage = Converter.pixelToPercentage(offsetX, timelineRect.width)

    this.storeService.updateTimelineMovePosition(offsetX)

    this.storeService.updateTooltipPosition(percentage)
    this.storeService.updateTooltipIsHidden(false)

    const { startTime, endTime } = this.mediaPeriodService.getRangeDateTimeline(timelineData.date, timelineData.mediaPeriodItems[0].startTimestamp);
    const timestamp = Converter.percentageToTimestamp(percentage, startTime.getTime(), endTime.getTime())

    this.storeService.updateTooltipLabel(this.formatDate(timestamp, 'HH:mm:ss'))
  }

  onTimelineLeave(): void {
    this.isTimelineEnter = false;
    if (!this.storeService.getSliderIsDragging()) {
      this.storeService.updateTooltipIsHidden(true);
    }
  }

  onTimelineUp(event: MouseEvent): void {
    const offsetX = this.getMouseOffsetTimeline(event);
    const timelineRect = this.timelineElementRef.nativeElement.getBoundingClientRect();
    const percentage = Converter.pixelToPercentage(offsetX, timelineRect.width);

    this.updateSliderPositionAndSelectedPlayerTime(percentage);
  }

  onTimelineEnter(): void {
    this.isTimelineEnter = true;
  }

  @HostListener('document:keydown', ['$event'])
  onKeydown(event: KeyboardEvent): void {
    const sliderPosition = this.storeService.getSliderPosition();
    switch (event.key) {
      case 'ArrowRight':
        this.updateSliderPositionAndSelectedPlayerTime(sliderPosition + 0.01)
        break;
      case 'ArrowLeft':
        this.updateSliderPositionAndSelectedPlayerTime(sliderPosition - 0.01)
        break;
    }
  }

  private getMouseOffsetTimeline(event: MouseEvent): number {
    const timelineRect = this.timelineElementRef.nativeElement.getBoundingClientRect();
    return event.clientX - timelineRect.left;
  }

  updateSliderPositionAndSelectedPlayerTime(percentage: number): void {
    const timelineData = this.storeService.getTimelineData()

    const { startTime, endTime } = this.mediaPeriodService.getRangeDateTimeline(timelineData.date, timelineData.mediaPeriodItems[0].startTimestamp)
    const timestamp = Converter.percentageToTimestamp(percentage, startTime.getTime(), endTime.getTime());
    const second = this.mediaPeriodService.calculateSecondForPlayer(timestamp, timelineData.mediaPeriodItems);

    if (this.mediaPeriodService.isTimestampInMediaPeriods(timestamp, timelineData.mediaPeriodItems)) {
      this.storeService.updateSliderPosition(percentage)
    }

    this.storeService.fireNewPlayerCurrentTime(second);
  }
}
