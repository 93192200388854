import { Injectable } from "@angular/core";
import { IMessageService, MessageSignalPayload } from "../models/message-service.model";
import { Observable, of } from "rxjs";
import { takeUntilDestroyed } from "@app/shared/rxjs/operator/take-until-destroyed";
import { take } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class MessageServiceRegistry {
  private messageServiceMap = new Map<string, IMessageService>()

  registerService(id: string, messageService: IMessageService): void {
    this.messageServiceMap.set(id, messageService);
  }

  getMessageService(id: string): IMessageService {
    return this.messageServiceMap.get(id)
  }
}
