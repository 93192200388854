import { Injectable } from "@angular/core"
import { Store } from "@ngrx/store";
import { BehaviorSubject, Subject } from "rxjs";
import { PeerConnectionState, StateEnum } from "../models/state.model";
import { DescriptionResult, GroupCameraByUrl, MessageType, PeerConnectionInfo, PeerConnectionType } from "../models/p2p.model";
import { MediaService } from "../media.service/media.service";
import { MediaStreamService } from "./media-stream.service";
import { iceServersList } from "./ice-servers";
import { HttpClient } from "@angular/common/http";
import { HumanErrorTranslationService } from "@app/shared/services/human-error-translation.service";
import { CustomInjectorService } from "../service/custom-injector.service";
import { HttpApiService } from "../service/http-api.service";
import { CameraWebSocketUrl } from "../models/api.model";
import { SignalType } from "../models/signal.model";

const RECV_ONLY_DIRECTION: RTCRtpTransceiverDirection = 'recvonly';
const SIGNALING_STATE_STABLE: RTCSignalingState = 'stable';
const VIDEO = 'video';
const AUDIO = 'audio';

@Injectable({ providedIn: 'root' })
export class PeerConnectionService {
  private peerConnectionListSubject = new BehaviorSubject<PeerConnectionInfo[]>([]);
  peerConnectionList$ = this.peerConnectionListSubject.asObservable();

  constructor(
    private httpApiService: HttpApiService,
    private mediaStreamService: MediaStreamService,
    private mediaService: MediaService,
    private customInjectorService: CustomInjectorService
  ) { }

  async createPeerConnection(streamIds: number[], signalType: SignalType): Promise<void> {
    switch (signalType) {
      case SignalType.http:
        this.initSignalHttpSingleCamera(streamIds[0])
        break;
      case SignalType.webSocket:
        this.initSignalWebSocket(streamIds)
        break;
    }
  }

  addReceiver(instanceId: string, streamId: number): void {
    this.addMedia(instanceId, streamId)
  }

  destroyPeerConnection(id: string): void {
    this.mediaService.removeMedia(id);
  }

  destroy(): void {
    this.peerConnectionListSubject.value
      .forEach(value => value.peerConnection.close());
    this.peerConnectionListSubject.next([]);

    this.mediaStreamService.destroy()
  }

  private initOnState(pcInfo: PeerConnectionInfo): void {
    pcInfo.peerConnection.oniceconnectionstatechange = () => {
      pcInfo.state$.next({
        type: StateEnum.iceConnectionState,
        state: pcInfo.peerConnection.iceConnectionState,
      })
    };

    pcInfo.peerConnection.onsignalingstatechange = () => {
      pcInfo.state$.next({
        type: StateEnum.signalingState,
        state: pcInfo.peerConnection.signalingState,
      })
    };

    pcInfo.peerConnection.onconnectionstatechange = () => {
      pcInfo.state$.next({
        type: StateEnum.connectionState,
        state: pcInfo.peerConnection.connectionState,
      })
    };
  }

  private setTransceiverDirection(pc: RTCPeerConnection): void {
    pc.addTransceiver(VIDEO, {
      'direction': RECV_ONLY_DIRECTION
    })
    pc.addTransceiver(AUDIO, {
      'direction': RECV_ONLY_DIRECTION
    })
  }

  private addMedia(instanceId: string, streamId: number): void {
    const mediaStream = new MediaStream();
    this.mediaStreamService.addMediaStream({
      instanceId,
      streamId,
      mediaStream
    })

    this.mediaService.addMedia({
      instanceId,
      type: 'webrtc',
      streamId,
      connectMedia: (element: HTMLVideoElement): void => {
        element.srcObject = mediaStream;
      },
      destroyMedia: (instanceId: string): void => {
        this.mediaStreamService.deleteMediaStream(instanceId)
      }
    })
  }

  private onTrack(pcInfo: PeerConnectionInfo): void {
    pcInfo.peerConnection.ontrack = event => {
      const streamId = this.getStreamIdFromTrack(event.track.label)
      this.mediaStreamService.addTrack(streamId, event.track)
    }
  }

  private onTrackSingleStream(streamId: number, pcInfo: PeerConnectionInfo): void {
    pcInfo.peerConnection.ontrack = event => {
      this.mediaStreamService.addTrack(streamId, event.track)
    }
  }

  private getStreamIdFromTrack(value: string): number {
    const match = value.match(/\d+$/);
    return match ? parseInt(match[0], 10) : 0;
  }

  private uniqueFilteredStreams(streamIds: number[]): number[] {
    return Array.from(new Set(streamIds.filter(Number.isInteger)));
  }

  private groupCamerasByUrl(cameraWebSocketUrl: CameraWebSocketUrl[]): GroupCameraByUrl[] {
    const map = new Map<string, number[]>();

    cameraWebSocketUrl.forEach(({ cameraId, webrtcUrl }) => {
      if (!map.has(webrtcUrl)) {
        map.set(webrtcUrl, []);
      }
      map.get(webrtcUrl)!.push(cameraId);
    });

    return Array.from(map, ([url, cameras]) => ({ url, cameras }));
  }

  private async initSignalWebSocket(streamIds: number[]): Promise<void> {
    const uniqueFilteredStreams = this.uniqueFilteredStreams(streamIds);

    const camerasWebsocketUrls = await this.httpApiService.getWebSocketMap(
      uniqueFilteredStreams.map(value => ({ cameraId: value }))
    ).toPromise()

    const groupCamerasByUrl = this.groupCamerasByUrl(camerasWebsocketUrls)

    groupCamerasByUrl
      .forEach(groupCamera => {
        const serviceDomainName = groupCamera.url.split(".rdva.svc")[0];

        const peerConnection = new RTCPeerConnection({
          iceServers: iceServersList
        });

        const peerConnectionInfo: PeerConnectionInfo = {
          streamIds: groupCamera.cameras,
          peerConnection,
          state$: new Subject<PeerConnectionState>()
        }

        this.initOnState(peerConnectionInfo);
        this.setTransceiverDirection(peerConnectionInfo.peerConnection)
        this.onTrack(peerConnectionInfo)

        const staging = this.getStaging()

        const messageService = this.customInjectorService.getWebSocketMessageService(serviceDomainName)
        messageService.init(`wss://${staging}${serviceDomainName}.rosdomofon.com/ws`)

        const signalService = this.customInjectorService.getSignalService(serviceDomainName, peerConnection, messageService)
        signalService.auth()

        setTimeout(() => {
          signalService.sendInit(groupCamera.cameras.map(id => ({ cameraId: id })))
        }, 400);

        const currentList = this.peerConnectionListSubject.value;
        this.peerConnectionListSubject.next([...currentList, peerConnectionInfo]);
      })
  }

  private initSignalHttpSingleCamera(cameraId: number): void {
    const peerConnection = new RTCPeerConnection({
      iceServers: iceServersList
    });

    const peerConnectionInfo: PeerConnectionInfo = {
      streamIds: [cameraId],
      peerConnection,
      state$: new Subject<PeerConnectionState>()
    }

    this.initOnState(peerConnectionInfo);
    this.setTransceiverDirection(peerConnectionInfo.peerConnection)
    this.onTrackSingleStream(cameraId, peerConnectionInfo)

    const messageService = this.customInjectorService.getHttpMessageService('')
    const signalService = this.customInjectorService.getSignalService(`${cameraId}`, peerConnection, messageService)

    signalService.sendOfferSingleCamera(cameraId)

    const currentList = this.peerConnectionListSubject.value;
    this.peerConnectionListSubject.next([...currentList, peerConnectionInfo]);
  }

  private getStaging() {
    const { hostname } = window.location;
    const parts = hostname.split(".");

    const isIP = /^\d{1,3}(\.\d{1,3}){3}$/.test(hostname);

    if (isIP || parts.length === 3) {
      return "";
    }

    if(parts.length !== 4 || parts[0] === 'preprod') {
      return ''
    }

    return  parts[0] + ".";
  }

}
