import { Injectable } from "@angular/core";
import { takeUntilDestroyed } from "@app/shared/rxjs/operator/take-until-destroyed";
import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";
import { IMediaStreamInfo } from "../models/p2p.model";

interface MediaStreamMap {

}

@Injectable({ providedIn: 'root' })
export class MediaStreamService {
  private mediaStreamInfoListSubject = new BehaviorSubject<IMediaStreamInfo[]>([]);
  mediaStreamInfoList$ = this.mediaStreamInfoListSubject.asObservable();

  private trackInstances = new Map<number, MediaStreamTrack[]>();

  addMediaStream(value: IMediaStreamInfo): void {
    this.deleteMediaStream(value.instanceId)

    if (this.trackInstances.has(value.streamId)) {
      this.trackInstances.get(value.streamId)
        .forEach(track => value.mediaStream.addTrack(track))
    }

    const currentValue = this.mediaStreamInfoListSubject.value;
    this.mediaStreamInfoListSubject.next([...currentValue, value])
  }

  addTrack(streamId: number, track: MediaStreamTrack): void {
    if (!this.trackInstances.has(streamId)) {
      this.trackInstances.set(streamId, []);
    }
    this.trackInstances.get(streamId)!.push(track);

    this.getMediaStreamInfoList(streamId)
      .forEach(mediaStreamInfo => {
        mediaStreamInfo.mediaStream.addTrack(track);
      });
  }

  deleteMediaStream(instanceId: string): void {
    const filteredStreamList = this.mediaStreamInfoListSubject.value
      .filter(value => value.instanceId !== instanceId)

    this.mediaStreamInfoListSubject.next(filteredStreamList)
  }

  destroy(): void {
    this.mediaStreamInfoListSubject.value
      .forEach(value => value.mediaStream.getTracks()
        .forEach(track => {
          track.stop();
          value.mediaStream.removeTrack(track);
        })
      );

    this.mediaStreamInfoListSubject.next([]);
    this.trackInstances.clear()
  }

  private getMediaStreamInfoList(streamId: number): IMediaStreamInfo[] {
    return this.mediaStreamInfoListSubject.value
      .filter(value => value.streamId == streamId)
  }
}
