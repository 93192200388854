import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from "@angular/core";
import { DecorateUntilDestroy, takeUntilDestroyed } from "@app/shared/rxjs/operator/take-until-destroyed";
import { TranslateService } from "@ngx-translate/core";
import { distinctUntilChanged, map } from "rxjs/operators";
import { StoreService } from "../../store/store.service";

@DecorateUntilDestroy()
@Component({
  selector: 'rd-video-time-manage-control',
  templateUrl: './time-manage-control.html',
  styleUrls: ['./time-manage-control.scss']
})

export class RdVideoTimeManageControlComponent {
  @Input() step: number;
  @Input() isBackgroundNone = false;

  constructor(private storeService: StoreService, private translate: TranslateService) { }

  prevClick(): void {
    const currentTime = this.storeService.getPlayerCurrentTime()
    this.storeService.fireNewPlayerCurrentTime(currentTime - this.step);
  }

  nextClick(): void {
    const currentTime = this.storeService.getPlayerCurrentTime()
    this.storeService.fireNewPlayerCurrentTime(currentTime + this.step);
  }

  getLabel(value: string): string {
    return `${value} ${this.step} ${this.translate.instant('shared.video_player.plyr.template.button.second')}`
  }
}
