import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { IMessageService, MessageSignalPayload } from "../models/message-service.model";
import { bufferWhen, tap } from "rxjs/operators";
import { takeUntilDestroyed } from "@app/shared/rxjs/operator/take-until-destroyed";

export interface WebSocketMessageData<T> {
  event: string;
  data: T;
}

@Injectable({ providedIn: 'root' })
export class WebSocketMessageService implements IMessageService {
  private socket: WebSocket;
  
  private flushMessageQueue$ = new Subject<void>();
  private messageSubject = new Subject();
  private messageQueue$ = new Subject<string>();

  init(url: string): void {
    this.socket = new WebSocket(url);
    this.socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      this.messageSubject.next(message);
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    this.socket.onclose = () => {
      console.error('WebSocket connection closed.');
    };

    this.socket.onopen = ()=>{
      this.flushMessageQueue$.next();
    }

    this.messageQueue$
      .pipe(
        takeUntilDestroyed(this, { destroyMethod: this.destroy }),
        bufferWhen(() => this.flushMessageQueue$)
      )
      .subscribe(messages => messages.forEach(msg => this.socket.send(msg)));
  }

  send<T>(event: string, data: T): void {
    const payload = { event, data };
    this.messageQueue$.next(JSON.stringify(payload))

    if (this.socket.readyState === WebSocket.OPEN) {
      this.flushMessageQueue$.next();
    }
  }

  on<T>(event: string): Observable<T> {
    return new Observable<T>((subscriber) => {
      this.messageSubject
        .pipe(takeUntilDestroyed(this, { destroyMethod: this.destroy }))
        .subscribe((message: WebSocketMessageData<T>) => subscriber.next(message.data));
    });
  }
  
  destroy(): void { }
}
