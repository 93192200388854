<div
  *ngIf="loading || requesting"
  [ngClass]="{ 'p-loader': loading }">
  <app-component-loader
    [sizePx]="40"
    [background]="true"
    [overTheComponent]="true"
    [borderRadiusPx]="20">
  </app-component-loader>
</div>
<app-account-selection
  *ngIf="accountStep"
  [abonent]="abonent"
  [isNewControl]="true"
  [accounts]="accounts"
  (accountSelected)="
    onAccountSelected($event.abonent, $event.account, $event.type)
  "></app-account-selection>

<div
  class="p-connection"
  *ngIf="!loading && accountStep == false">
  <div class="p-connection-header">
    <i
      [class]="'pi'"
      [ngClass]="{
        'pi-plus-circle approve':
          dynamicDialogConfig.data.signUp.signUpType === 'CONNECTION',
        'pi-minus-circle reject':
          dynamicDialogConfig.data.signUp.signUpType === 'DISCONNECTION'
      }"></i>
    <span>
      {{ dynamicDialogConfig.data.signUp.signUpType | autoConnectionStatus }}
    </span>
  </div>
  <hr />
  <div class="p-connection-services">
    <span class="p-connection-services__phone">
      {{ dynamicDialogConfig.data.signUp.phone }}
    </span>
    <span class="p-connection-services__address">
      {{ dynamicDialogConfig.data.signUp.address | address }}
      {{ 'sign_ups.abonent_sign_up.short_flat_name' | translate }}
      {{ dynamicDialogConfig.data.signUp.address.flat }}
    </span>
    <ul>
      <li *ngFor="let service of serviceInfoResponse">
        <div class="p-connection-services-controls">
          <div class="p-connection-services-controls-text">
            <span class="p-connection-services-controls-text__header">
              {{
                "service-navbar.tab.action.addition." +
                  ServiceType[service.type] | translate
              }}
            </span>
            <span class="p-connection-services-controls-text__name">
              {{ service.name }}
            </span>
            <div>
              <span
                *ngIf="
                  dynamicDialogConfig.data.signUp.signUpType ===
                    'DISCONNECTION' && service.control.value === false
                "
                class="p-connection-services-controls-text__selected__disconnected">
                {{ 'sign_ups.abonent_sign_up.will_be_disconnected' | translate }} 
                <span
                  *ngIf="
                    dynamicDialogConfig.data.signUp.signUpType ===
                    'DISCONNECTION'
                  "
                  class="p-connection-services-controls-text__selected__disconnected__circle"></span>
              </span>
              <div *ngIf="isSoftwareIntercom(service.type)" class="intercom-tube">
                <p-inputSwitch class="intercom-tube-tumbler" [formControl]="hasPhysicalTube"></p-inputSwitch>
                <span class="p-connection-services-controls-text intercom-tube-text">{{ 'software.intercom.flats.field.intercom_tube' | translate }}</span>
              </div>
              <span
                class="p-connection-services-controls-text__selected"
                *ngIf="isAbonentChoice(service)">
                {{ 'sign_ups.abonent_sign_up.selected_by_abonent' | translate }}
              </span>
            </div>
          </div>
          <p-inputSwitch [formControl]="service.control"></p-inputSwitch>
        </div>
      </li>
    </ul>
  </div>
  <hr *ngIf="dynamicDialogConfig.data.signUp.signUpType === 'DISCONNECTION'"/>
  <div class="p-connection-comment" *ngIf="dynamicDialogConfig.data.signUp.signUpType === 'DISCONNECTION'">
    <span>
      <span class="p-connection-services-controls-text__header">{{ 'sign_ups.abonent_sign_up.reason' | translate }}: </span>
      {{
        dynamicDialogConfig.data.signUp?.disconnectionReason
          ? dynamicDialogConfig.data.signUp.disconnectionReason
          : 'sign_ups.abonent_sign_up.reason_not_specified' | translate
      }}
    </span>
  </div>
  <hr />
  <div class="p-connection-info">
    <div class="p-connection-info-status">
      <span class="p-connection-info-status__text">
        {{ dynamicDialogConfig.data.signUp | signUpStatus }}
      </span>
    </div>
    <div
      class="p-connection-info-tube"
      *ngIf="!dynamicDialogConfig.data.signUp.isVirtual">
      <img
        src="/assets/icons/ic-tube-dark.svg"
        alt="error" />
      <span> {{ 'sign_ups.abonent_sign_up.there_is_intercom_tube' | translate }}</span>
    </div>
  </div>
  <hr />
  <div class="p-connection-dates">
    <div class="p-connection-dates-created">
      <span>{{ 'sign_ups.abonent_sign_up.created_at_short' | translate }}:</span>
      <span>{{ signUpCreatedAt }}</span>
    </div>
    <div class="p-connection-dates-changed">
      <span>{{ 'sign_ups.abonent_sign_up.updated_at_short' | translate }}:</span>
      <span>{{ signUpUpdatedAt }}</span>
    </div>
  </div>
  <div class="p-connection-actions">
    <button
      pButton
      [label]="
        dynamicDialogConfig.data.signUp.signUpType === 'CONNECTION'
          ? ('sign_ups.abonent_sign_up.connect_sign_up' | translate)
          : ('sign_ups.abonent_sign_up.disconnect_sign_up' | translate)
        "
      class="p-connection-actions__button"
      [ngClass]="{
        'p-button-danger': dynamicDialogConfig.data.signUp.signUpType !== 'CONNECTION', 
        'p-button-text': dynamicDialogConfig.data.signUp.signUpType !== 'CONNECTION'
      }"
      (click)="onAction(dynamicDialogConfig.data.signUp.signUpType)"></button>
  </div>
</div>
