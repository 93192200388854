<div #playerWrapperElement 
  class="video-manager-player2__wrapper" 
>
  <p *ngIf="!isSmallSize" class="video-manager-player2--title">{{ camera?.camera?.id }} {{ camera?.camera?.address | address }}</p>
  <i *ngIf="isSmallSize" class="video-manager-player2--title video-manager-player2--title__like-icon pi pi-info-circle"
     pTooltip="{{ camera?.camera?.id }} {{ camera?.camera?.address | address }}"></i>
  
  <rd-video-manager 
    [componentId]="componentId"
    [camera]="camera"
    [streamId]="cameraId"
    [isShowOpenDoor]="isCanOpenDoor"
    [isShowControl]="!hideSmallActions"
    (clickVideoWrapperEvent)="clickVideoWrapper()"
    (openDoorEvent)="openDoor()"
    (openVideoShotPopupEvent)="openVideoShotPopup($event)"
    (requestFullscreenEvent)="onRequestFullScreen($event)"
  ></rd-video-manager>
  
</div>

<ng-template #emptyOptionEvent>
  <span>{{'shared.video_player.plyr.template.message.no_events' | translate }}</span>
</ng-template>

<ng-template #downloadBtn>
  <p-button *ngIf="!errorLoadingPlaylist || !errorLoadingVideo"
    [label]="'shared.video_player.plyr.template.button.get_video_shot' | translate"
    styleClass="p-button-info p-button-text"
    (onClick)="openVideoshotPopup()"></p-button>
</ng-template>

<ng-template #openDoorBtn>
  <p-button *ngIf="isCanOpenDoor" [label]="'shared.video_player.plyr.template.button.open_door'| translate"
    icon="pi pi-sign-in pi-icon--blue" iconPos="left"
    styleClass="p-button-info p-button-text"
    (onClick)="openDoor()"></p-button>
</ng-template>

<ng-template #changeModeToLiveBtn 
  let-iconPos="iconPos"
>
  <p-button [label]="'shared.video_player.plyr.template.button.live' | translate"
    styleClass="p-button-info p-button-text" 
    [icon]="'pi pi-circle-fill pi-icon--red'"
    [iconPos]="iconPos || 'left'"
    (onClick)="changeModeToLive()"
  ></p-button>
</ng-template>

<ng-template #changeModeToArchiveBtn 
  let-iconPos="iconPos"
>
  <p-button [label]="'shared.video_player.plyr.template.button.archive' | translate"
    styleClass="p-button-info p-button-text" 
    [icon]="'pi pi-save'"
    [iconPos]="iconPos || 'left'"
    (onClick)="changeModeToArchive()"
  ></p-button>
</ng-template>

<ng-template #changeEventBtn let-next="next">
  <p-button [label]="(next ? 'shared.video_player.plyr.template.button.next_event2' : 'shared.video_player.plyr.template.button.prev_event2') | translate"
    [icon]="next ? 'pi pi-angle-right' : 'pi pi-angle-left'" [iconPos]="next ? 'right' : 'left'"
    styleClass="p-button-info p-button-text"
    (onClick)="changeEvent(next)"></p-button>
</ng-template>

<ng-template #changeSomeSecBtn let-plus="plus">
  <p-button [label]="(plus ? 'shared.video_player.plyr.template.button.plus_10_second' : 'shared.video_player.plyr.template.button.minus_10_second') | translate"
    [icon]="plus ? 'pi pi-angle-double-right' : 'pi pi-angle-double-left'" [iconPos]="plus ? 'right' : 'left'"
    styleClass="p-button-info p-button-text"
    (onClick)="changeTimelineOnSomeSec(plus)"></p-button>
</ng-template>
