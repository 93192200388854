<div class="manage-container">
  <button class="button" (mousedown)="clickPrev()">
    <img class="icon" src="assets/icons/ic-video-event-previous.svg">
    <div class="text">
      {{ 'shared.video_player.plyr.template.button.prev' | translate }}
      <br />
      {{ 'shared.video_player.plyr.template.button.event' | translate }}
    </div>

  </button>
  <button class="button" (mousedown)="clickNext()">
    <div class="text">
      {{ 'shared.video_player.plyr.template.button.next' | translate }}
      <br />
      {{ 'shared.video_player.plyr.template.button.event' | translate }}
    </div>
    <img class="icon rotate" src="assets/icons/ic-video-event-previous.svg">
  </button>
</div>