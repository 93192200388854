import { HttpClient } from "@angular/common/http";
import { Injectable, InjectionToken, Injector } from "@angular/core";
import { HumanErrorTranslationService } from "@app/shared/services/human-error-translation.service";
import { HttpMessageService } from "../message-service/http-message.service";
import { MessageServiceRegistry } from "../message-service/message-service-registry.service";
import { WebSocketMessageService } from "../message-service/web-socket-message.service";
import { IMessageService } from "../models/message-service.model";
import { SignalService } from "../peer-connection/signal-serivice/signal.service";
import { HttpApiService } from "./http-api.service";

export const SIGNAL_SERVICE_ID_TOKEN = new InjectionToken<number>('SignalServiceId');
export const MESSAGE_SERVICE_TOKEN = new InjectionToken<IMessageService>('IMessageService');

@Injectable({ providedIn: 'root' })
export class CustomInjectorService {
  constructor(
    private messageServiceRegistry: MessageServiceRegistry,
    private injector: Injector
  ) { }

  getWebSocketMessageService(id: string): WebSocketMessageService {
    const customInjector = Injector.create({
      providers: [
        {
          provide: WebSocketMessageService, useClass: WebSocketMessageService
        },
      ],
    });
    
    const messageService = customInjector.get(WebSocketMessageService);
    this.messageServiceRegistry.registerService(id, messageService)

    return messageService
  }

  getHttpMessageService(id: string): HttpMessageService {
    const customInjector = Injector.create({
      providers: [
        {
          provide: HttpApiService, useValue: this.injector.get(HttpApiService)
        },
        {
          provide: HttpClient, useValue: this.injector.get(HttpClient)
        },
        {
          provide: HumanErrorTranslationService,
          useValue: this.injector.get(HumanErrorTranslationService)
        },
        {
          provide: HttpMessageService, useClass: HttpMessageService
        },
      ],
    });

    const messageService = customInjector.get(HttpMessageService);
    this.messageServiceRegistry.registerService(id, messageService)

    return messageService
  }

  getSignalService(id: string, peerConnection: RTCPeerConnection, messageService: IMessageService): SignalService {
    const signalService = Injector.create({
      providers: [
        { provide: RTCPeerConnection, useValue: peerConnection },
        { provide: SIGNAL_SERVICE_ID_TOKEN, useValue: id },
        { provide: MESSAGE_SERVICE_TOKEN, useValue: messageService },
        { provide: SignalService, useClass: SignalService },
      ],
    }).get(SignalService);

    return signalService;
  }
}