import { Inject, Injectable } from "@angular/core"
import { takeUntilDestroyed } from "@app/shared/rxjs/operator/take-until-destroyed"
import { DescriptionResult, MessageType } from "../../models/p2p.model"
import { PeerConnectionService } from "../peer-connection.service"
import { IMessageService, MessageSignalPayload } from "../../models/message-service.model"
import { MessageServiceRegistry } from "../../message-service/message-service-registry.service"
import { MESSAGE_SERVICE_TOKEN, SIGNAL_SERVICE_ID_TOKEN } from "../../service/custom-injector.service"
import { Event } from "../../models/signal.model"
import { CameraId } from "../../models/api.model"
import { LocalStorageGeneralKey, LocalStorageHelper } from "@app/shared/entities"

const SIGNALING_STATE_STABLE: RTCSignalingState = 'stable';

@Injectable({ providedIn: 'root' })
export class SignalService {
  private makingOffer = false;
  private ignoreOffer = false;

  constructor(
    @Inject(SIGNAL_SERVICE_ID_TOKEN) private id: string,
    @Inject(MESSAGE_SERVICE_TOKEN) private messageService: IMessageService,
    private peerConnection: RTCPeerConnection
  ) {
    this.onSignal()
  }

  destroy(): void { }

  onSignal(): void {
    this.messageService
      .on<MessageSignalPayload>(Event.signal)
      .pipe(takeUntilDestroyed(this, { destroyMethod: this.destroy }))
      .subscribe(async (signalMessage) => {
        try {
          switch (signalMessage.type) {
            case MessageType.offer:
              await this.createAndSendDescription(signalMessage.payload.description);
              break;
            case MessageType.answer:
              await this.setRemoteDescription(signalMessage.payload.description);
              break;
            case MessageType.candidate:
              this.addIceCandidate(signalMessage.payload.candidate);
              break;
          }
        } catch (error) {
          console.error('Error during processing signal', error);
        }
      })
  }

  auth(): void {
    const authToken = LocalStorageHelper.getItem(LocalStorageGeneralKey.AUTH_TOKEN);
    this.messageService.send(Event.auth, {
      token: authToken,
    })
  }

  sendInit(rtspDataItems: CameraId[]): void {
    this.messageService.send(Event.signal, {
      type: MessageType.init,
      rtspDataItems
    })
  }

  async sendOfferSingleCamera(id: number): Promise<void> {
    const offerResult = await this.createOffer();
    this.messageService.send('signal', {
      id,
      type: MessageType.offer,
      payload: {
        description: offerResult.description
      }
    })
  }

  async sendOffer(rtspDataItems?: CameraId[]): Promise<void> {
    const offerResult = await this.createOffer();
    this.messageService.send('signal', {
      type: MessageType.offer,
      payload: {
        description: offerResult.description
      },
      rtspDataItems
    })
  }

  private async createAndSendDescription(description?: RTCSessionDescription | null): Promise<void> {
    const answerResult = await this.createAnswer(description);
    if (!answerResult.success) {
      await this.sendOffer();
      return;
    }

    this.messageService.send(Event.signal, {
      type: MessageType.answer,
      payload: {
        description: answerResult.description
      }
    });
  }

  async setRemoteDescription(description?: RTCSessionDescription | null): Promise<void> {
    if (description?.type === MessageType.answer) {
      this.ignoreOffer = false;
    }
    if (!description || !this.peerConnection) {
      return;
    }

    await this.peerConnection.setRemoteDescription(description);
  }

  async createOffer(): Promise<DescriptionResult> {
    if (!this.peerConnection.localDescription) {
      await this.peerConnection.setLocalDescription();
    }
    return {
      success: true,
      description: this.peerConnection.localDescription
    }
  }

  async createAnswer(description?: RTCSessionDescription | null): Promise<DescriptionResult> {
    this.ignoreOffer = this.makingOffer || this.peerConnection.signalingState !== SIGNALING_STATE_STABLE;

    if (this.ignoreOffer) {
      return {
        success: false
      };
    }

    await this.setRemoteDescription(description);
    await this.peerConnection.setLocalDescription();

    return {
      success: true,
      description: this.peerConnection.localDescription
    };
  }

  private addIceCandidate(candidate?: RTCIceCandidate | null): void {
    try {
      if (!candidate || !this.peerConnection.remoteDescription) {
        return;
      }
      this.peerConnection.addIceCandidate(candidate);
    } catch (error) {
      if (!this.ignoreOffer) {
        console.error('Error add ice candidate', error);
      }
    }
  }

}
