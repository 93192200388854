import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarService } from '@app/shared/components/snackbar';
import { RdvaPlaylistItem } from '@app/shared/entities/integrations';
import { Constants } from '@app/shared/helpers';
import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import { DialogWrapperData } from '@app/shared/ui/dialog-wrapper';
import Hls, { ErrorTypes, Fragment } from 'hls.js';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { VideoPlayerCustomClickEventType, VideoPlayerErrorTypes, VideoPlayerFragmentShortDate, VideoPlayerHlsError, VideoPlayerMode, VideoPlayerVideoshotRequest, VideoshotPopupState } from './models';
import { GetVideoshotPopupPlaylists, VideoPlayerChangeVideoshotState, VideoPlayerState } from './store';
import { VideoPlayerVideoshotPopupComponent } from './video-player-videoshot-popup';
import { MAX_FATALS_COUNT, TZ_OFFSET_IN_MILLISECONDS, TZ_OFFSET_IN_MINUTES } from './video-player.constants';
import { CameraApiService, RdaApiService } from '@app/shared/entities/rd';
import { catchError, delay, filter, map, skip, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { DecorateUntilDestroy, takeUntilDestroyed } from '@app/shared/rxjs/operator/take-until-destroyed';
import { RdVideoService } from '@app/shared/video';
import { WebRTCExperimentHelpers } from './helpers/webrtc-expriment-helpers';
import { Option } from '@app/shared/video/models/select.model';
import { DatePipe } from '@angular/common';
import { HlsHelper } from '@app/shared/video/helpers/hls-helper';
import { HlsManifestItems, TypeReceiver } from '@app/shared/video/models/hls.model';
import { VideoShotData } from '@app/shared/video/models/video.models';
import { SignalType } from '@app/shared/video/models/signal.model';

@DecorateUntilDestroy()
@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoPlayerComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('video') public videoElement: ElementRef<HTMLVideoElement>;
  private loadingSubject = new BehaviorSubject(false);
  loading$ = this.loadingSubject.asObservable();

  mdWDownBreakpoint$: Observable<boolean> = this.resolution.getBreakpoint(ResolutionBreakpoint.MD_W_DOWN);

  public releId: number = null;
  public rdaUid: string = null;
  public componentId = Date.now().toString(36) + Math.random().toString(36).substr(2, 9);

  @Input() token: string;
  @Input() rdvaUri: string;
  @Input() set active(active: boolean) {
    this.mode.next(active ?
      WebRTCExperimentHelpers.isCompanyInExperiment() ? VideoPlayerMode.LIVE_WEBRTC : VideoPlayerMode.LIVE_HLS
      : VideoPlayerMode.ARCHIVE)
  }
  @Input() cameraId: number;
  @Input() depthInHours: number;

  private unSubscribe$: Subject<boolean> = new Subject<boolean>();
  private delayCameraInter$: Subject<void> = new Subject<void>();

  public readonly VideoPlayerMode = VideoPlayerMode;

  private mode = new BehaviorSubject<VideoPlayerMode>(WebRTCExperimentHelpers.isCompanyInExperiment() ? VideoPlayerMode.LIVE_WEBRTC : VideoPlayerMode.LIVE_HLS)
  mode$ = this.mode.asObservable();

  readonly timeStepManage = 10;
  
  private init$ = new BehaviorSubject(false);

  eventsOptions$ = this.init$
    .pipe(
      switchMap(()=> this.rdVideoService.onTimelineData$(this.componentId)),
      map(data => {
        const eventOptions = data.eventTimestamps
          .map(event => this.rdVideoService.createOption(event, this.formatDate(event.startTimestamp, 'HH:mm:ss')));

        this.rdVideoService.updateEventOptions(this.componentId, eventOptions);
        return eventOptions;
      })
    )

  dateArchiveOptions$ = this.init$
    .pipe(
      switchMap(()=> this.rdVideoService.onPlayListItems$(this.componentId)),
      map(playlistItems => playlistItems
        .map(playlist => this.rdVideoService.createOption(playlist, this.formatDate(playlist.date, 'dd MMMM')))
      )
    )

  currentPlaylistOption$ = this.init$
    .pipe(
      switchMap(()=> this.rdVideoService.onCurrentPlaylist$(this.componentId)),
      map(currentPlaylist => this.rdVideoService.createOption(currentPlaylist, this.formatDate(currentPlaylist.date, 'dd MMMM')))
    )

  public get isCanOpenDoor(): boolean {
    return this.releId !== null;
  }

  constructor(
    private dialog: MatDialog,
    private snackbar: SnackbarService,
    private resolution: ResolutionService,
    private rdaApiService: RdaApiService,
    private translate: TranslateService,
    private cameraApiService: CameraApiService,
    private store: Store<VideoPlayerState>,
    private rdVideoService: RdVideoService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.init$.next(true);

    this.cameraApiService.getCameraRDAConfigById(this.cameraId)
      .pipe(
        catchError((error: HttpErrorResponse) => of(error)),
        takeUntil(this.unSubscribe$),
      ).subscribe((res) => {
        if (res instanceof HttpErrorResponse) {
          console.warn('The door does not have a config');
        } else {
          if (res.releId !== null && res.uid !== null) {
            this.releId = res.releId;
            this.rdaUid = res.uid;
          }
        }
        this.initOnChangeModePlayer();
        this.delayCameraInter$.next();
      });
  }

  ngAfterViewInit(): void {
    this.rdVideoService.initWebRTC([this.cameraId], SignalType.http)
  }

  ngOnDestroy(): void {
    this.rdVideoService.destroy();

    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  getModeButtonName(): string {
    switch (this.mode.value) {
      case VideoPlayerMode.LIVE_WEBRTC:
        return 'LIVE old';
      case VideoPlayerMode.LIVE_HLS:
        return this.translate.instant('shared.video_player.plyr.template.button.archive');
      case VideoPlayerMode.ARCHIVE:
        if (WebRTCExperimentHelpers.isCompanyInExperiment()) {
          return 'LIVE new';
        }
        return 'LIVE';
    }
  }

  openVideoShotPopup(): void {
    const items = this.rdVideoService.getPlaylistHlsManifestItems(this.componentId);
    const videoShotRangeData = HlsHelper.getVideoShotRangeData(items, this.cameraId);

    if (!videoShotRangeData) {
      return;
    }

    const data: DialogWrapperData<VideoPlayerVideoshotRequest, null> = {
      title: this.translate.instant('shared.video_player.video_shot.title'),
      componentName: 'GetVideoshot',
      body: {
        minDate: videoShotRangeData.minDate,
        maxDate: videoShotRangeData.maxDate,
        depthInHours: this.depthInHours,
        rdvaUri: this.rdvaUri,
        cameraId: this.cameraId,
        token: this.token,
        isMobile: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN)
      }
    };

    this.dialog.open(VideoPlayerVideoshotPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      autoFocus: false,
      data
    });
  }

  openDoor(): void {
    this.rdaApiService.openDoor(this.rdaUid, this.releId).pipe(
      catchError((error: HttpErrorResponse) => of(error)),
      take(1),
      takeUntilDestroyed(this)
    ).subscribe((response) => {
      if (response instanceof HttpErrorResponse) {
        this.snackbar.showMessage(
          this.translate.instant('shared.video_player.message.open.failed.text', {
            text: response.message
          }),
          'error'
        );
        return;
      }
      this.snackbar.showMessage(
        this.translate.instant('shared.video_player.message.open.success'),
        'success'
      );
    });
  }

  handleSelectionEvents(option: Option<VideoPlayerFragmentShortDate>): void {
    this.rdVideoService.updateCurrentEvent(this.componentId, option);
  }

  handleSelectionPlaylist(option: Option<RdvaPlaylistItem>): void {
    this.rdVideoService.updateCurrentPlaylist(this.componentId, option.value);
  }

  private initOnChangeModePlayer(): void {
    this.mode$
      .pipe(
        // delay so that the video element has time to render
        delay(1),
        takeUntilDestroyed(this)
      )
      .subscribe((mode) => {
        switch (mode) {
          case VideoPlayerMode.LIVE_WEBRTC:
            this.initLiveWebRTC();
            break;
          case VideoPlayerMode.LIVE_HLS:
            this.initLiveHls();
            break;
          case VideoPlayerMode.ARCHIVE:
            this.initArchive();
            break;
          default:
            break;
        }
      })
  }

  private initArchive(): void {
    this.initHls(TypeReceiver.ARCHIVE);
  }

  private initLiveHls(): void {
    this.initHls(TypeReceiver.LIVE);
  }

  private initLiveWebRTC(): void {
    this.rdVideoService.addReceiverWebRTC({
      instanceId: this.componentId,
      streamId: this.cameraId
    });
  }

  private initHls(type: TypeReceiver): void {
    this.rdVideoService.addReceiverHls({
      instanceId: this.componentId,
      streamId: this.cameraId,
      hostUri: this.rdvaUri,
      type
    });
  }

  private changeMode(): void {
    switch (this.mode.value) {
      case VideoPlayerMode.LIVE_WEBRTC:
        this.rdVideoService.destroyWebRTCStream(this.componentId);
        this.mode.next(VideoPlayerMode.LIVE_HLS)
        break;
      case VideoPlayerMode.LIVE_HLS:
        this.rdVideoService.destroyHlsStream(this.componentId);
        this.mode.next(VideoPlayerMode.ARCHIVE)
        break;
      case VideoPlayerMode.ARCHIVE:
        this.rdVideoService.destroyHlsStream(this.componentId);
        if (WebRTCExperimentHelpers.isCompanyInExperiment()) {
          this.mode.next(VideoPlayerMode.LIVE_WEBRTC)
          break;
        }
        this.mode.next(VideoPlayerMode.LIVE_HLS)
        break;
    }
  }

  private getVideoShotRangeData(items: HlsManifestItems): VideoShotData {
    const firstPlaylistUrl = HlsHelper.getPlaylistRelUrl(true, items[0]);
    const lastPlaylistUrl = HlsHelper.getPlaylistRelUrl(false, items[1]);

    let minDate = HlsHelper.getDateFromFragmentRelUrl(firstPlaylistUrl, this.cameraId);
    const maxDate = HlsHelper.getDateFromFragmentRelUrl(lastPlaylistUrl, this.cameraId);

    minDate = HlsHelper.shiftMinDateForward(minDate, maxDate);

    return {
      minDate,
      maxDate
    }
  }

  private handleHlsError(): void {
    this.rdVideoService.onHlsConnectionErrorType$(this.componentId)
      .pipe(
        skip(MAX_FATALS_COUNT),
        filter(error => error.fatal),
        takeUntilDestroyed(this)
      )
      .subscribe(error => {
        this.showFatalErrorMessage(error.type, error.details);

        if (this.active) {
          this.changeMode();
        }
      })
  }

  /**
   * Prepare fatal error message
   * @param errorType Hls error type
   * @param details Hls eror details
   */
  private showFatalErrorMessage(errorType: string, details: string): void {
    let reason: string;

    switch (errorType) {
      case Hls.ErrorTypes.NETWORK_ERROR:
        reason = details === 'levelEmptyError'
          ? this.translate.instant('shared.video_player.message.error.reason.empty')
          : this.translate.instant('shared.video_player.message.error.reason.error')
          ;
        break;
      case Hls.ErrorTypes.MEDIA_ERROR:
        reason = this.translate.instant('shared.video_player.message.error.reason.media');
        break;
      default:
        reason = this.translate.instant('shared.video_player.message.error.reason.unknown');
    }

    this.snackbar.showMessage(
      this.translate.instant('shared.video_player.message.error.reason.unknown', {
        text: reason
      })
    );
  }

  private formatDate(timestamp: number, format: string = 'dd MMMM'): string {
    return this.datePipe.transform(timestamp - TZ_OFFSET_IN_MILLISECONDS, format);
  }
}
