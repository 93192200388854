<app-paginated-page-wrapper
  #paginatedPageWrapper
  filterInputPlaceholder="{{ 'video.surveillance.list.placeholder.filter' | translate }}"
  [paginatorData]="pageData$ | async"
  (filterInputChanged)="onFilterInputChanged()"
  (pageChanged)="onPageChanged()"
>
  <ng-container page-template-actions>
    <button
      mat-raised-button
      color="primary-inverse"
      (click)="onCreate()">
      <mat-icon>add</mat-icon>
      <ng-container *ngIf="!(xlWDownBreakpoint$ | async)">
        {{ 'video.surveillance.list.button.create' | translate }}
      </ng-container>
    </button>
  </ng-container>

  <ng-container page-template-content>
    <mat-table
    [dataSource]="videoSurveillanceList$ | async"
    *ngIf="pageData?.totalElements !== 0"
    matSort
    (matSortChange)="onSort($event)"
  >
    <ng-container matColumnDef="customName">
      <mat-header-cell *matHeaderCellDef>
        {{ 'video.surveillance.list.field.name' | translate }}
      </mat-header-cell>
      <mat-cell
        data-label="name"
        *matCellDef="let element"
      >
        {{ element.customName || element.name }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="camerasStates">
      <mat-header-cell *matHeaderCellDef>
        {{ 'video.surveillance.list.field.cameras_states' | translate }}
      </mat-header-cell>
      <mat-cell
        data-label="camerasStates"
        *matCellDef="let element"
      >
        <mat-chip-list
          [class.mat-chip-list--place-content]="xlWDownBreakpoint$ | async">
          <ng-container
            *ngIf="
              element.camerasStates.active === 0 &&
                element.camerasStates.inactive === 0;
              else camerasStates
            ">
            <mat-chip [disabled]="true" [selectable]="false">
              {{ 'video.surveillance.list.message.empty' | translate }}
            </mat-chip>
          </ng-container>

          <ng-template #camerasStates>
            <mat-chip
              color="success"
              [disabled]="true"
              [selectable]="false"
              *ngIf="element.camerasStates.active > 0"
            >
              {{ 'video.surveillance.list.enum.state.online' | translate }}:
              {{ element.camerasStates.active }}
            </mat-chip>

            <mat-chip
              color="error"
              [disabled]="true"
              [selectable]="false"
              *ngIf="element.camerasStates.inactive > 0"
            >
              {{ 'video.surveillance.list.enum.state.offline' | translate }}:
              {{ element.camerasStates.inactive }}
            </mat-chip>
          </ng-template>
        </mat-chip-list>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell data-label="actions" *matHeaderCellDef></mat-header-cell>
      <mat-cell
        data-label="actions"
        *matCellDef="let element"
      >
        <button
          matTooltip="{{ 'video.surveillance.list.tooltip.delete' | translate }}"
          [matTooltipPosition]="'before'"
          mat-icon-button
          color="error"
          (click)="onDelete(element?.id, element.name)">
          <mat-icon aria-label="Delete">delete</mat-icon>
        </button>

        <a
          class="action-btn"
          mat-icon-button
          [routerLink]="'/services/video-surveillance/' + element.id"
          [queryParams]="{
            query: paginatedPageWrapper.filterInput.value,
            page: paginatedPageWrapper.paginator.pageIndex,
            name: element.name,
            companyId: element?.company?.id,
            type: previosQueryType.VIDEO
          }">
          <mat-icon aria-label="Edit">navigate_next</mat-icon>
        </a>
      </mat-cell>
    </ng-container>

    <mat-header-row
      [style.display]="(xlWDownBreakpoint$ | async) ? 'none' : 'flex'"
      *matHeaderRowDef="displayedColumns"
    >
      {{ 'video.surveillance.list.field.actions' | translate }}
    </mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <ng-container *ngIf="(loading$ | async) === false && pageData?.totalElements === 0">
    <app-info-block>
      <ng-container info-block-text>
        {{ 'software.intercoms.message.empty' | translate }}
      </ng-container>
    </app-info-block>
  </ng-container>
    
    <ngx-skeleton-loader
      *ngIf="loading$ | async"
      count="10"
      [theme]="{
        'border-radius': '8px',
        'margin-top': (xlWDownBreakpoint$ | async) ? '0px' : '10px',
        height: (xlWDownBreakpoint$ | async) ? '225px' : '81px'
      }"></ngx-skeleton-loader>
  </ng-container>
</app-paginated-page-wrapper>
