export enum Links {
    Mailing = 'https://wiki.rosdomofon.com/ru/Поддержка/Техникам_и_администраторам/Личный_кабинет_RDEA/Инструкция_по_работе_с_личным_кабинетом(RDEA)/Раздел_Рассылки',
    Payments = 'https://wiki.rosdomofon.com/ru/Поддержка/Возможности_Системы_РосДомофон/Сбор_платежей',
    AutoPayments = "https://wiki.rosdomofon.com/ru/Поддержка/Возможности_Системы_РосДомофон/Сбор_платежей/ЮKassa/Автоплатежи",
    SignUpS = 'https://wiki.rosdomofon.com/ru/Поддержка/Техникам_и_администраторам/Личный_кабинет_RDEA/Инструкция_по_работе_с_личным_кабинетом(RDEA)/Обращения_и_заявки_на_подключение_от_абонентов',
    SoftwareIntercom = 'https://wiki.rosdomofon.com/ru/Поддержка/Возможности_Системы_РосДомофон/Функционал_услуги_Умный_Домофон',
    Gates = 'https://wiki.rosdomofon.com/ru/Поддержка/Возможности_Системы_РосДомофон/Функционал_услуги_Калитка',
    VideoSurveillance = 'https://wiki.rosdomofon.com/ru/Поддержка/Техникам_и_администраторам/Личный_кабинет_RDEA/Инструкция_по_работе_с_личным_кабинетом(RDEA)/Настройка_услуги_Видеонаблюдение',
    Inst = 'https://wiki.rosdomofon.com/Поддержка/Возможности_Системы_РосДомофон',
    News = 'https://wiki.rosdomofon.com/Поддержка/Возможности_Системы_РосДомофон/Что_нового',
    Offline = 'https://wiki.rosdomofon.com/ru/Поддержка/Техникам_и_администраторам/РосДомофон_адаптер(РДА)/Если_РДА_оффлайн',
    AddCamera = 'https://wiki.rosdomofon.com/Поддержка/Техникам_и_администраторам/Личный_кабинет_RDEA/Инструкция_по_работе_с_личным_кабинетом(RDEA)/Настройка_услуги_Умный_домофон/Добавление_камеры_в_услугу',
    Wizard = 'https://wiki.rosdomofon.com/ru/Поддержка/Техникам_и_администраторам/Личный_кабинет_RDEA/Инструкция_по_работе_с_личным_кабинетом(RDEA)/Настройка_услуги_Умный_домофон',
    Camera = 'https://wiki.rosdomofon.com/Поддержка/Техникам_и_администраторам/Камеры',
    Abonents = 'https://wiki.rosdomofon.com/ru/Поддержка/Техникам_и_администраторам/Личный_кабинет_RDEA/Инструкция_по_работе_с_личным_кабинетом(RDEA)/Вкладки_услуг_Умный_домофон_Калитка_Видеонаблюдение/Вкладка_Абоненты',
    Activity = 'https://wiki.rosdomofon.com/ru/Поддержка/Техникам_и_администраторам/Личный_кабинет_RDEA/Инструкция_по_работе_с_личным_кабинетом(RDEA)/Вкладки_услуг_Умный_домофон_Калитка_Видеонаблюдение/Вкладка_Активность',
    Settings = 'https://wiki.rosdomofon.com/ru/Поддержка/Техникам_и_администраторам/Личный_кабинет_RDEA/Инструкция_по_работе_с_личным_кабинетом(RDEA)/Вкладки_услуг_Умный_домофон_Калитка_Видеонаблюдение/Вкладка_Настройки',
    AddSoftwareIntercome = 'https://wiki.rosdomofon.com/Поддержка/Техникам_и_администраторам/Личный_кабинет_RDEA/Инструкция_по_работе_с_личным_кабинетом(RDEA)/Добавление_услуги_Умный_домофон_или_Калитка',
    AddHardwareIntercome = 'https://wiki.rosdomofon.com/ru/Поддержка/Возможности_Системы_РосДомофон/Функционал_услуги_Управление_Трубкой_Домофона',
    AddVideo = 'https://wiki.rosdomofon.com/ru/Поддержка/Техникам_и_администраторам/Личный_кабинет_RDEA/Инструкция_по_работе_с_личным_кабинетом(RDEA)/Добавление_услуги_Видеонаблюдение',
    AddAdapter = 'https://wiki.rosdomofon.com/Поддержка/Техникам_и_администраторам/Личный_кабинет_RDEA/Инструкция_по_работе_с_личным_кабинетом(RDEA)/Настройка_услуги_Умный_домофон/Добавление_адаптера_в_услугу',
    Company = 'https://wiki.rosdomofon.com/ru/Поддержка/Техникам_и_администраторам/Личный_кабинет_RDEA/Инструкция_по_работе_с_личным_кабинетом(RDEA)/Раздел_Компания',
    HowToBuy = 'https://wiki.rosdomofon.com/Поддержка/Как_продать',
    Telegram = 'https://t.me/rosdomofon',
    ApartmentVideoSurveillance = 'https://wiki.rosdomofon.com/ru/Поддержка/Возможности_Системы_РосДомофон/Персональное_видеонаблюдение',
    Videodispatcher = 'https://wiki.rosdomofon.com/ru/Поддержка/Возможности_Системы_РосДомофон/Видеостена',
    Integrations = 'https://wiki.rosdomofon.com/ru/Поддержка/Возможности_Системы_РосДомофон/Интеграции/Чаты_мониторинга'
}
