import { Injectable, Injector } from "@angular/core";
import { IMessageService } from "../models/message-service.model";
import { Observable, Subject } from "rxjs";
import { ApiService } from "@app/shared/api";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "environments/environment";
import { HumanErrorTranslationService } from "@app/shared/services/human-error-translation.service";
import { take, tap } from "rxjs/operators";
import { HttpApiService } from "../service/http-api.service";

const HTTP_SINGLE_REQUEST = 1;

@Injectable({ providedIn: 'root' })
export class HttpMessageService extends ApiService implements IMessageService {
  private responseSubject = new Subject();

  constructor(
    httpClient: HttpClient, 
    humanErrorTranslationService: HumanErrorTranslationService,
    private httpApiService: HttpApiService
  ) {
    super(httpClient, humanErrorTranslationService, environment.camerasApiUrl, ['v1', 'v2'])
  }

  send<T>(event: string, data: T): void {
    this.httpApiService.sendDescription<T>(data)
      .pipe(take(HTTP_SINGLE_REQUEST),)
      .subscribe(res => this.responseSubject.next(res))
  }

  on<T>(event: string): Observable<T> {
    return this.responseSubject.asObservable() as Observable<T>;
  }
}
