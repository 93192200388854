import { Injectable } from "@angular/core";
import { BehaviorSubject, merge, Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { StoreService } from "./store.service";

export type StoreMap = Map<string, StoreService>

@Injectable({ providedIn: 'root' })
export class StoreRegistryService {
  private stores = new Map<string, StoreService>();
  private storesOject = new BehaviorSubject(this.stores)

  selectStore(id: string): Observable<StoreService> {
    return this.storesOject.asObservable()
      .pipe(
        filter(value => value.has(id)),
        map(value => value.get(id))
      )
  }

  registerStore(id: string, store: StoreService): void {
    if(!this.stores.has(id)){
      this.stores.set(id, store);
      this.storesOject.next(this.stores)
    }
  }

  getStore(id: string): StoreService {
    return this.stores.get(id)
  }

  deleteStore(id: string): void  {
    if(this.stores.has(id)){
      const store = this.stores.get(id);
      store.destroy()
      this.stores.delete(id)
    }
  }

  destroy(): void {
    this.stores.clear()
  }

  private mergeWithStreamId<T>(
    observableSelector: (storeService: StoreService) => Observable<T>
  ): Observable<{ id: string; data: T }> {
    const observables = [...this.stores].map(([id, storeService]) =>
      observableSelector(storeService).pipe(
        map((data) => ({
          id,
          data,
        }))
      )
    );

    return merge(...observables);
  }
}