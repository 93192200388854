import { AfterContentInit, Component, ContentChild, ElementRef, Input, Output } from "@angular/core";
import { DecorateUntilDestroy, takeUntilDestroyed } from "@app/shared/rxjs/operator/take-until-destroyed";
import { StoreService } from "../../store/store.service";
import { distinctUntilChanged, filter, map, take } from "rxjs/operators";
import { PlayerStateEnum } from "../../models/state.model";
import { MediaService } from "../../media.service/media.service";
import { MediaInfo } from "../../models/video.models";
import { StoreRegistryService } from "../../store/store-registry.service";

@DecorateUntilDestroy()
@Component({
  selector: 'rd-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class RdVideoComponent implements AfterContentInit {
  @Input() streamId: number;
  @Input() componentId: string;


  @ContentChild('video') videoElementRef: ElementRef<HTMLVideoElement>;

  private mediaInstance: MediaInfo; 

  constructor(
    private mediaService: MediaService,
    private storeService: StoreService,
    private storeRegistryService: StoreRegistryService
  ) { }

  ngAfterContentInit(): void {
    this.storeRegistryService.registerStore(this.componentId, this.storeService)
    this.storeService.updateIsLoadData(true)
    this.storeService.updateVideoElementRef(this.videoElementRef.nativeElement)

    const elementIsMuted = this.videoElementRef.nativeElement.muted;
    this.storeService.updatePlayerIsMuted(elementIsMuted)

    const elementVolume = this.videoElementRef.nativeElement.volume;
    this.storeService.updatePlayerVolume(elementVolume)

    this.mediaService.mediaInfoList$
      .pipe(
        map(list => list.find(value => value.instanceId === this.componentId)),
        filter(value => !!value),
        take(1),
        takeUntilDestroyed(this)
      )
      .subscribe(media => {
        this.mediaInstance = media;
        media.connectMedia(this.videoElementRef.nativeElement);
      })

    this.storeService.onNewPlayerCurrentTime$()
      .pipe(
        distinctUntilChanged(),
        takeUntilDestroyed(this)
      )
      .subscribe((value) => {
        this.storeService.updatePlayerCurrentTime(value);
        this.videoElementRef.nativeElement.currentTime = Math.ceil(value);
      })

    this.storeService.onVolumeChange$()
      .pipe(takeUntilDestroyed(this))
      .subscribe((value) => {
        this.storeService.updatePlayerVolume(value)
        this.videoElementRef.nativeElement.volume = value
      })

    this.storeService.onMutedChange$()
      .pipe(takeUntilDestroyed(this))
      .subscribe((value) => {
        this.storeService.updatePlayerIsMuted(value)
        this.videoElementRef.nativeElement.muted = value
      });

    this.storeService.onRequestPictureInPicture$()
      .pipe(takeUntilDestroyed(this))
      .subscribe(() => {
        if (document.pictureInPictureElement) {
          document.exitPictureInPicture()
          this.videoElementRef.nativeElement.requestPictureInPicture();
        } else {
          this.videoElementRef.nativeElement.requestPictureInPicture();
        }
      })

    this.storeService.onNewPlayerState$()
      .pipe(takeUntilDestroyed(this))
      .subscribe((state) => {
        this.storeService.updatePlayerState(state)
        this.storeService.updateIsLoadData(false)

        switch (state) {
          case PlayerStateEnum.PLAY:
            this.videoElementRef.nativeElement.play();
            break;
          case PlayerStateEnum.PAUSE:
            this.videoElementRef.nativeElement.pause();
            break;
        }
      })

    this.videoElementRef.nativeElement.addEventListener('timeupdate', this.onTimeUpdate.bind(this));
    this.videoElementRef.nativeElement.addEventListener('play', this.onPlayUpdate.bind(this));
    this.videoElementRef.nativeElement.addEventListener('pause', this.onPauseUpdate.bind(this));

    this.videoElementRef.nativeElement.addEventListener('waiting', this.onWaitingUpdate.bind(this));
    this.videoElementRef.nativeElement.addEventListener('playing', this.onPlayingUpdate.bind(this));

    document.addEventListener('fullscreenchange', this.onFullscreenChange.bind(this));

  }

  onTimeUpdate(): void {
    const currentTime = this.videoElementRef.nativeElement.currentTime;
    this.storeService.updatePlayerCurrentTime(currentTime);
  }

  onPlayUpdate(): void {
    this.storeService.updateIsLoadData(false)
    this.storeService.updatePlayerState(PlayerStateEnum.PLAY)
  }

  onPauseUpdate(): void {
    this.storeService.updateIsLoadData(false)
  }

  onWaitingUpdate(): void {
    this.storeService.updateIsLoadData(true)
  }

  onPlayingUpdate(): void {
    this.storeService.updateIsLoadData(false)
  }

  onFullscreenChange(event): void {
    const isFullScreen = !!document.fullscreenElement;
    if (!isFullScreen) {
      this.storeService.updatePlayerIsFullScreen(isFullScreen);
    }
  }
}
