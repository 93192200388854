<div class="video-manager-show-screen">
  <p-card class="video-manager-show-screen__card">
    <div *ngIf="!isLoaded && !screen" style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;">
      <app-component-loader sizePx="50" [overTheComponent]="false" class="video-stream-popup-loader"></app-component-loader>
    </div>
    <ng-container *ngIf="isLoaded && !!screen">
      <div class="video-manager-show-screen__wrapper">
        <div class="video-manager-show-screen__header__wrapper">
          <div class="video-manager-show-screen__header__left">
            <p-button icon="pi pi-chevron-left" styleClass="p-button-raised p-button-text p-button-rounded p-button-info p-button-sm"
                      [routerLink]="['/services/video-manager']"></p-button>
          </div>
          <div class="video-manager-show-screen__header__center video-manager-show-screen__header__center__wrapper">
            <p class="video-manager-show-screen__header__center--title">{{screen?.name}}</p>
            <div class="video-manager-show-screen__header__center__sizer__wrapper">
              <p-button icon="pi pi-minus" styleClass="p-button-raised p-button-text p-button-rounded p-button-info p-button-sm"
                        (onClick)="changeSize(false)"></p-button>
              <div class="video-manager-show-screen__header__center__sizer--item">
                <p-slider [(ngModel)]="sizeCoeff" [step]="2" [min]="10" [max]="25"
                          [animate]="true" (onChange)="applySize($event)"></p-slider>
              </div>
              <p-button icon="pi pi-plus" styleClass="p-button-raised p-button-text p-button-rounded p-button-info p-button-sm"
                        (onClick)="changeSize(true)"></p-button>
            </div>
          </div>
          <div class="video-manager-show-screen__header__right">
            <p-button [label]="'video.manager.common.edit' | translate" styleClass="p-button-sm"
                      [queryParams]="{back: true}" [routerLink]="['/services/video-manager/edit', screen?.id]"></p-button>
          </div>
        </div>
        <div #contentWrapper class="video-manager-show-screen__content__wrapper">
          <div class="screen__view__wrapper" [ngClass]="GetViewScheme(screen?.layout)">
            <ng-container *ngFor="let camera of screen?.cameras; let cameraIndex = index; trackBy: trackByFn">
              <ng-container *ngIf="cameraIndex + 1 <= GetMaxBlockByViewScheme(screen?.layout)">
                <div [ngStyle]="{width: getWidth(contentWrapper, cameraIndex), height: getHeight(contentWrapper, cameraIndex)}"
                     class="screen__view__item__wrapper">
                  <div class="screen__view__item__preview__wrapper">
                    <div class="screen__view__item__preview">
                      <div *ngIf="!!camera?.camera" class="screen__view__item__preview--content"
                           [ngClass]="{'screen__view__item__preview--full-screen': maximazed[camera.cameraId]}">
                        <div class="screen__view__item__preview__action__top">
                          <p-button icon="pi {{maximazed[camera.cameraId] ? 'pi-window-minimize' : 'pi-window-maximize'}}"
                                    styleClass="p-button-raised p-button-text p-button-rounded p-button-info p-button-sm screen__view__item__preview__action__top--item"
                                    (click)="onFullScreen(camera)"></p-button>
                        </div>
                        <app-video-manager-player [width]="getWidth(contentWrapper, cameraIndex)"
                          [height]="getHeight(contentWrapper, cameraIndex)"
                          [token]="authToken"
                          [hideSmallActions]="screen?.layout === ViewVariants.largeOneAndFourOnRightSide && cameraIndex !== 0"
                          [camera]="camera"
                          [depthInHours]="getDepthInHours(camera)"
                          (clickVideoWrapperEvent)="selectMainVideoView(camera, cameraIndex)">
                        </app-video-manager-player>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </p-card>
</div>
