import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { MediaInfo } from "../models/video.models";

@Injectable({ providedIn: 'root' })
export class MediaService {
  private mediaInfoListSubject = new BehaviorSubject<MediaInfo[]>([]);
  mediaInfoList$ = this.mediaInfoListSubject.asObservable();

  addMedia(mediaInfo: MediaInfo): void {
    const currentValue = this.mediaInfoListSubject.value;
    this.mediaInfoListSubject.next([...currentValue, mediaInfo])
  }

  getMedia(instanceId: string): MediaInfo {
    const currentValue = this.mediaInfoListSubject.value
    return currentValue.find(value => value.instanceId === instanceId)
  }

  getCountBindStream(instanceId: string): number {
    const currentValue = this.mediaInfoListSubject.value
    return currentValue.filter(value => value.instanceId === instanceId).length
  }

  removeMedia(instanceId: string): void {
    this.mediaInfoListSubject.next(this.mediaInfoListSubject.value
      .filter(value => value.instanceId !== instanceId)
    )
  }

  destroy(): void {
    this.mediaInfoListSubject.next([])
  }
}
