import { state } from "@angular/animations";
import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TZ_OFFSET_IN_MILLISECONDS, VideoPlayerFragmentShortDate } from "@app/shared/components/video-player";
import { RdvaPlaylistItem } from "@app/shared/entities/integrations";
import { takeUntilDestroyed } from "@app/shared/rxjs/operator/take-until-destroyed";
import { ErrorData, ManifestLoadedData } from "hls.js";
import { BehaviorSubject, Observable } from "rxjs";
import { filter, withLatestFrom } from "rxjs/operators";
import { HlsManifestItems } from "../models/hls.model";
import { ArchivePlaylist, VideoPlayerMode } from "../models/player.model";
import { Option } from "../models/select.model";
import {
  Event,
  IEvent,
  PlayerStateEnum
} from "../models/state.model";
import { TimelineData } from "../models/timeline.model";
import { videoInitialState, VideoState } from "./state";
import { Store } from "./store";

@Injectable({ providedIn: 'root' })

export class StoreService {

  constructor(private store: Store<VideoState, IEvent>) {
    this.store.init(videoInitialState)
  }

  destroy(): void {
    this.store.init(videoInitialState)
  }

  getVideoElementRef(): HTMLVideoElement {
    return this.store.get('videoElementRef');
  }

  updateVideoElementRef(value: HTMLVideoElement): void {
    this.store.setField('videoElementRef', value);
  }

  getPlaylistHlsManifestItems(): HlsManifestItems {
    return this.store.get('playlistHlsManifestItems');
  }

  selectPlaylistHlsManifestItems$(): Observable<HlsManifestItems> {
    return this.store.select('playlistHlsManifestItems');
  }

  updatePlaylistHlsManifestItems(value: HlsManifestItems): void {
    this.store.setField('playlistHlsManifestItems', value);
  }
  
  getManifestLoadedData(): ManifestLoadedData {
    return this.store.get('manifestLoadedData');
  }

  selectManifestLoadedData$(): Observable<ManifestLoadedData> {
    return this.store.select('manifestLoadedData');
  }

  updateManifestLoadedData(value: ManifestLoadedData): void {
    this.store.setField('manifestLoadedData', value);
  }

  fireHlsConnectionErrorType(value: ErrorData): void {
    this.store.fire('hlsConnectionError', value);
  }

  onHlsConnectionErrorType$(): Observable<ErrorData> {
    return this.store.on('hlsConnectionError');
  }

  getMode(): VideoPlayerMode {
    return this.store.get('mode');
  }

  selectMode$(): Observable<VideoPlayerMode> {
    return this.store.select('mode');
  }

  updateMode(value: VideoPlayerMode): void {
    this.store.setField('mode', value);
  }

  fireMutedChange(value: boolean): void {
    this.store.fire('mutedChange', value);
  }

  onMutedChange$(): Observable<boolean> {
    return this.store.on('mutedChange');
  }

  getPlayerIsMuted(): boolean {
    return this.store.get('playerIsMuted');
  }

  selectPlayerIsMuted$(): Observable<boolean> {
    return this.store.select('playerIsMuted');
  }

  updatePlayerIsMuted(value: boolean): void {
    this.store.setField('playerIsMuted', value, true);
  }

  onVolumeChange$(): Observable<number> {
    return this.store.on('volumeChange');
  }

  fireVolumeChange(value: number): void {
    this.store.fire('volumeChange', value);
  }

  getPlayerVolume(): number {
    return this.store.get('playerVolume');
  }

  selectPlayerVolume$(): Observable<number> {
    return this.store.select('playerVolume');
  }

  updatePlayerVolume(value: number): void {
    this.store.setField('playerVolume', value, true);
  }

  getPlayerIsFullScreen(): boolean {
    return this.store.get('playerIsFullScreen');
  }

  selectPlayerIsFullScreen$(): Observable<boolean> {
    return this.store.select('playerIsFullScreen');
  }

  updatePlayerIsFullScreen(value: boolean): void {
    this.store.setField('playerIsFullScreen', value);
  }

  fireRequestPictureInPicture(): void {
    this.store.fire('requestPictureInPicture');
  }

  onRequestPictureInPicture$(): Observable<null> {
    return this.store.on('requestPictureInPicture');
  }

  fireNewPlayerState(value: PlayerStateEnum): void {
    this.store.fire('newPlayerState', value)
  }

  onNewPlayerState$(): Observable<PlayerStateEnum> {
    return this.store.on('newPlayerState')
  }

  getPlayerState(): PlayerStateEnum {
    return this.store.get('playerState');
  }

  selectPlayerState$(): Observable<PlayerStateEnum> {
    return this.store.select('playerState');
  }

  updatePlayerState(value: PlayerStateEnum): void {
    this.store.setField('playerState', value);
  }

  getPlayListItems(): RdvaPlaylistItem[] {
    return this.store.get('playListItems');
  }

  selectPlayListItems$(): Observable<RdvaPlaylistItem[]> {
    return this.store.select('playListItems');
  }

  updatePlayListItems(value: RdvaPlaylistItem[]): void {
    this.store.setField('playListItems', value);
  }

  getDateArchiveOptions(): Option[] {
    return this.store.get('dateArchiveOptions');
  }

  selectDateArchiveOptions$(): Observable<Option[]> {
    return this.store.select('dateArchiveOptions');
  }

  updateDateArchiveOptions(value: Option[]): void {
    this.store.setField('dateArchiveOptions', value);
  }

  getCurrentPlaylist(): RdvaPlaylistItem {
    return this.store.get('currentPlaylist');
  }

  selectCurrentPlaylist$(): Observable<RdvaPlaylistItem> {
    return this.store.select('currentPlaylist');
  }

  updateCurrentPlaylist(value: RdvaPlaylistItem): void {
    this.store.setField('currentPlaylist', value);
  }

  getEventOptions(): Option<VideoPlayerFragmentShortDate>[] {
    return this.store.get('eventsOptions');
  }

  selectEventOptions$(): Observable<Option<VideoPlayerFragmentShortDate>[]> {
    return this.store.select('eventsOptions');
  }

  updateEventOptions(value: Option<VideoPlayerFragmentShortDate>[]): void {
    this.store.setField('eventsOptions', value);
  }

  getCurrentEvent(): Option<VideoPlayerFragmentShortDate> {
    return this.store.get('currentEvent');
  }

  selectCurrentEvent$(): Observable<Option<VideoPlayerFragmentShortDate>> {
    return this.store.select('currentEvent');
  }

  updateCurrentEvent(value: Option<VideoPlayerFragmentShortDate>): void {
    this.store.setField('currentEvent', value);
  }

  getTimelineData(): TimelineData {
    return this.store.get('timelineData');
  }

  selectTimelineData$(): Observable<TimelineData> {
    return this.store.select('timelineData');
  }

  updateTimelineData(value: TimelineData): void {
    this.store.setField('timelineData', value);
  }

  getTimelineElement(): HTMLDivElement {
    return this.store.get('timelineElement');
  }

  updateTimelineElement(value: HTMLDivElement): void {
    this.store.setField('timelineElement', value)
  }

  getSliderIsDragging(): boolean {
    return this.store.get('sliderIsDragging');
  }

  selectSliderIsDragging$(): Observable<boolean> {
    return this.store.select('sliderIsDragging');
  }

  updateSliderIsDragging(value: boolean): void {
    this.store.setField('sliderIsDragging', value);
  }

  getSliderPosition(): number {
    return this.store.get('sliderPosition');
  }

  selectSliderPosition$(): Observable<number> {
    return this.store.select('sliderPosition');
  }

  updateSliderPosition(value: number): void {
    this.store.setField('sliderPosition', value);
  }

  selectTooltipIsHidden$(): Observable<boolean> {
    return this.store.select('tooltipIsHidden');
  }

  updateTooltipIsHidden(value: boolean): void {
    this.store.setField('tooltipIsHidden', value);
  }

  selectTooltipLabel$(): Observable<string> {
    return this.store.select('tooltipLabel');
  }

  updateTooltipLabel(value: string): void {
    this.store.setField('tooltipLabel', value);
  }

  selectTooltipPosition$(): Observable<number> {
    return this.store.select('tooltipPosition');
  }

  updateTooltipPosition(value: number): void {
    this.store.setField('tooltipPosition', value);
  }

  onSelectedDateTime$(): Observable<Event> {
    return this.store.on('selectedDateTime');
  }

  fireSelectedDateTime(data: number): void {
    this.store.fire('selectedDateTime', data);
  }

  onSelectedPlaylistPlayer$(): Observable<Event> {
    return this.store.on('selectedPlaylistPlayer');
  }

  onNewPlayerCurrentTime$(): Observable<number> {
    return this.store.on('newPlayerCurrentTime');
  }

  fireNewPlayerCurrentTime(value: number): void {
    this.store.fire('newPlayerCurrentTime', value);
  }

  fireSelectedPlaylistPlayer(data: Option): void {
    this.store.fire('selectedPlaylistPlayer', data);
  }

  updateTimelineMovePosition(value: number): void {
    this.store.setField('timelineMovePosition', value);
  }

  updateSelectedDateTime(value: number): void {
    this.store.setField('selectedDateTime', value);
  }

  getPlayerCurrentTime(): number {
    return this.store.get('playerCurrentTime');
  }

  selectPlayerCurrentTime$(): Observable<number> {
    return this.store.select('playerCurrentTime');
  }

  updatePlayerCurrentTime(value: number): void {
    this.store.setField('playerCurrentTime', value);
  }
  
  getIsHoveredControl(): boolean {
    return this.store.get('isHoveredControl');
  }

  selectIsHoveredControl$(): Observable<boolean> {
    return this.store.select('isHoveredControl');
  }

  updateIsHoveredControl(value: boolean): void {
    this.store.setField('isHoveredControl', value);
  }

  getIsHoveredTimeline(): boolean {
    return this.store.get('isHoveredTimeline');
  }

  selectIsHoveredTimeline$(): Observable<boolean> {
    return this.store.select('isHoveredTimeline');
  }

  updateIsHoveredTimeline(value: boolean): void {
    this.store.setField('isHoveredTimeline', value);
  }

  updateSelectedPlaylistPlayer(value: Option): void {
    this.store.setField('selectedPlaylistPlayer', value);
  }

  fireHlsPlaylistLoadError(error: HttpErrorResponse): void {
    this.store.fire('hlsPlaylistLoadError', error)
  }

  onHlsPlaylistLoadError$(): Observable<HttpErrorResponse> {
    return this.store.on('hlsPlaylistLoadError')
  }

  selectIsLoadData(): Observable<boolean> {
    return this.store.select('isLoadData')
  }

  updateIsLoadData(value: boolean): void {
    this.store.setField('isLoadData', value)
  }
}