export enum DialogWrapperSize {
    SM = '515px',
    MD = '800px',
    LG = '1000px',
    XL = '1200px',
    MAX = '100%',
    PX515 = '515px',
    PX524 = '524px',
    PX548 = '548px'
}
