import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Converter } from '../../helpers/converter';
import { StoreService } from '../../store/store.service';

@Component({
  selector: 'rd-video-timeline-tooltip',
  templateUrl: './timeline-tooltip.html',
  styleUrls: ['./timeline-tooltip.scss']
})
export class RdVideoTimelineTooltipComponent {
  @ViewChild('tooltip') tooltipElementRef: ElementRef<HTMLDivElement>;

  label$ = this.storeService.selectTooltipLabel$();
  isHidden$ = this.storeService.selectTooltipIsHidden$();

  position$ = this.storeService.selectTooltipPosition$()
    .pipe(
      filter(()=> !!this.tooltipElementRef),
      map(value => {
        const tooltipRect = this.tooltipElementRef.nativeElement.getBoundingClientRect();
        const tooltipRectWidth = tooltipRect.width

        const timelineRect = this.storeService.getTimelineElement().getBoundingClientRect()
        const timelineRectWidth = timelineRect.width

        const percentage = Converter.pixelToPercentage(tooltipRectWidth, timelineRectWidth);

        return value - (percentage / 2);
      })
    )

  constructor(private storeService: StoreService) { }
}