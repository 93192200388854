import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DecorateUntilDestroy, takeUntilDestroyed } from "@app/shared/rxjs/operator/take-until-destroyed";
import { PlayerStateEnum } from "../../models/state.model";
import { map, startWith, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";
import { StoreService } from "../../store/store.service";

enum PlayOrPauseIconUrlEnum {
  play = 'assets/icons/pause.svg',
  pause = 'assets/icons/play.svg'
}

@DecorateUntilDestroy()
@Component({
  selector: 'rd-video-play-control',
  templateUrl: './play-control.html',
  styleUrls: ['./play-control.scss']
})
export class RdVideoPlayControlComponent {
  @Input() isActive: boolean = false;
  @Input() isRoundButton: boolean = false;
  @Input() isHideInPauseState: boolean = false;

  private isHideInPauseStateSubject = new BehaviorSubject(false);

  isHideInPauseState$ = this.storeService.selectPlayerState$().pipe(
    map((state) =>
      this.isHideInPauseState ? state === PlayerStateEnum.PLAY : false
    ),
    startWith(false)
  );
  
  iconUlr$ = this.storeService.selectPlayerState$().pipe(
    map(state =>
      state === PlayerStateEnum.PLAY
        ? PlayOrPauseIconUrlEnum.play
        : PlayOrPauseIconUrlEnum.pause
    ),
    startWith(PlayOrPauseIconUrlEnum.pause)
  );
  

  constructor(private storeService: StoreService) { }

  onControlClick(): void {
    const playerState = this.storeService.getPlayerState() === PlayerStateEnum.PLAY ? PlayerStateEnum.PAUSE : PlayerStateEnum.PLAY;
    this.storeService.fireNewPlayerState(playerState)
  }
}
