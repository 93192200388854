import { VideoPlayerFragmentShortDate } from "@app/shared/components/video-player";
import { RdvaPlaylistItem } from "@app/shared/entities/integrations";
import { ManifestLoadedData } from "hls.js";
import { ArchivePlaylist, VideoPlayerMode } from "../models/player.model";
import { Option } from "../models/select.model";
import { PlayerStateEnum } from "../models/state.model";
import { TimelineData } from "../models/timeline.model";

export interface HlsData {
  playlistHlsManifestItems: [string, string];
  manifestLoadedData: ManifestLoadedData;
}
export interface TimelineState {
  timelineData: TimelineData;
  timelineMovePosition: number;
  timelineElement: HTMLDivElement;
}

export interface TooltipState {
  tooltipPosition: number;
  tooltipLabel: string;
  tooltipIsHidden: boolean;
}

export interface SliderState {
  sliderPosition: number;
  sliderIsDragging: boolean;
}

export interface PlayControl {
  playerState: PlayerStateEnum;
}

export interface Player {
  mode: VideoPlayerMode,
  playListItems: RdvaPlaylistItem[],
  currentPlaylist: RdvaPlaylistItem,
  dateArchiveOptions: Option[],
  eventsOptions: Option<VideoPlayerFragmentShortDate>[],
  currentEvent: Option<VideoPlayerFragmentShortDate>,
  playerIsMuted: boolean;
  playerVolume: number,
  playerIsFullScreen: boolean;
  isHoveredControl: boolean;
  isHoveredTimeline: boolean;
  isLoadData: boolean;
}

export interface VideoState extends Player, TooltipState, SliderState, TimelineState, PlayControl, HlsData {
  videoElementRef: HTMLVideoElement;
  selectedDateTime: number;
  playerCurrentTime: number;
  selectedPlaylistPlayer: Option;
};

export const videoInitialState: VideoState = {
  videoElementRef: undefined,
  playlistHlsManifestItems: undefined,
  manifestLoadedData: undefined,
  mode: undefined,
  playListItems: undefined,
  dateArchiveOptions: undefined,
  currentPlaylist: undefined,
  eventsOptions: undefined,
  currentEvent: undefined,
  timelineData: undefined,
  timelineMovePosition: undefined,
  timelineElement: undefined,
  selectedDateTime: undefined,
  playerCurrentTime: undefined,
  selectedPlaylistPlayer: undefined,
  sliderPosition: undefined,
  sliderIsDragging: undefined,
  tooltipPosition: undefined,
  tooltipLabel: undefined,
  tooltipIsHidden: true,
  playerState: undefined,
  playerIsFullScreen: false,
  playerIsMuted: undefined,
  playerVolume: undefined,
  isHoveredControl: false,
  isHoveredTimeline: false,
  isLoadData: false
}