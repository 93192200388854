import { Component } from '@angular/core';
import { NativeDialogService } from '@app/shared/components/info-panel/services/native-dialog.service';
import { InfoPanelComponent } from '@app/shared/components/info-panel/components/info-panel/info-panel.component';
import { Response } from '@app/shared/components/info-panel/models/response';
import { Position } from '@app/shared/components/info-panel/models/position';

@Component({
  selector: 'app-base-info-panel',
  standalone: true,
  template: ``
})
export class BaseInfoPanelComponent {
  constructor(protected dialogService: NativeDialogService) { }
  public showResponseStatus(summary: string, detail: string, type: Response, position?: Position, life?: number): void {
    this.dialogService.showDialog(InfoPanelComponent, (instance: InfoPanelComponent, close) => {
      instance.summary = summary;
      instance.detail = detail;
      instance.response = type;
      instance.position = position ? position : Position.br;
      instance.life = life ?? 10000;
      instance.close.subscribe(() => close(true));
    }).then();
  }
}
