import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-component-loader',
  templateUrl: './component-loader.component.html',
  styleUrls: ['./component-loader.component.scss']
})
export class ComponentLoaderComponent {
  @Input() background: boolean;
  @Input() whiteCircle: boolean;
  @Input() overTheComponent = true;
  @Input() sizePx = 30;
  @Input() borderRadiusPx: number;
}
