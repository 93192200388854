import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })

export class HumanErrorTranslationService {

  private ERROR_PATTERS = {
    found_flat_ranges_with_cross_values: /Found flat ranges with cross values/,
    no_such_file_or_directory: / No such file or directory/,
    ogrn_required: /Ogrn required/,
    user_with_email: /User with email/,
    no_message_recipients_found: /No message recipients found/,
    connected_accounts_found: /connected accounts found/,
    intercom_software_service_address_is_busy: /Found another software intercom service connected to entrance/,
    monitoring_chat_id_already_exist: /Telegram notification chat with id \S+ for company \S+ is already exists/
  };

  constructor(private translateService: TranslateService) { }

  translate(errorText: string): string | null {
    const keyword = this.findErrorKeyword(errorText);

    if (!keyword) {
      return null;
    }

    switch (keyword) {
      case this.ERROR_PATTERS.found_flat_ranges_with_cross_values:
        const regex = /(\d+-\d+).*?№ (\d+).*?(\d+-\d+).*?№ (\d+)/;
        const matches = errorText.match(regex);

        const rangesOne = matches[1];
        const rangesTwo = matches[3];
        const entranceOne = matches[2];
        const entranceTwo = matches[4];

        return this.translateService.instant('shared.additional.ranges.form.message.found_flat_ranges_with_cross_values', { rangesOne, rangesTwo, entranceOne, entranceTwo });
      case this.ERROR_PATTERS.no_such_file_or_directory:
        return this.translateService.instant('shared.entities.integrations.rdva.camera.logs.error.no_such_file_or_directory');
      case this.ERROR_PATTERS.ogrn_required:
        return this.translateService.instant('company.signup.request.form.error.ogrn_required');
      case this.ERROR_PATTERS.user_with_email:
        return this.translateService.instant('company.roles.create.message.submit.error.user_with_exists');
      case this.ERROR_PATTERS.no_message_recipients_found:
        return this.translateService.instant('mailing.page.new.message.send.error.no_message_recipients_found');
      case this.ERROR_PATTERS.connected_accounts_found:
        return this.translateService.instant('services.video_surveillance.flats.list.message.delete_video_surveillance.error.connected_accounts_found');
      case this.ERROR_PATTERS.intercom_software_service_address_is_busy:
        return this.translateService.instant('services.entrances.message.create.error.intercom_software_service_address_is_busy');
      case this.ERROR_PATTERS.monitoring_chat_id_already_exist:
        return this.translateService.instant('integrations.monitoring.modals.create.message.chat_id_alread_exist');
      default:
        return null;
    }
  }

  translateFromPath(path: string, params?: string[]): string {
    if (params && params.length > 0) {
      const objectParams = params.reduce((acc, value, index) => {
        acc[index] = value;
        return acc;
      }, {});
      
      return this.translateService.instant(path, objectParams)
    }
    
    return this.translateService.instant(path)
  }

  private findErrorKeyword(errorText: string): RegExp | null {
    for (const pattern in this.ERROR_PATTERS) {
      if (this.ERROR_PATTERS[pattern].test(errorText)) {
        return this.ERROR_PATTERS[pattern];
      }
    }
    return null;
  }
}
