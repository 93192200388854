import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "@app/shared/api";
import { HumanErrorTranslationService } from "@app/shared/services/human-error-translation.service";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { CameraId, CameraWebSocketUrl } from "../models/api.model";
import { MessageSignalPayload } from "../models/message-service.model";

@Injectable({ providedIn: 'root' })

export class HttpApiService extends ApiService {
  constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService) {
    super(httpClient, humanErrorTranslationService, environment.camerasApiUrl, ['v1', 'v2'])
  }

  sendDescription<T>(data: T): Observable<MessageSignalPayload> {
    return this.post('/webrtc', 1, JSON.stringify(data), { 
      headers: new HttpHeaders({'Content-Type':'application/json; charset=utf-8'})
    })
  }

  getWebSocketMap(data: CameraId[]): Observable<CameraWebSocketUrl[]> {
    return this.post('/webrtc/cameras', 1, JSON.stringify(data), { 
      headers: new HttpHeaders({'Content-Type':'application/json; charset=utf-8'})
    })
  }
}