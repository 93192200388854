import { RdeaHls } from "@app/shared/components/video-player";

export interface HlsInfo {
  id: string;
  hls: RdeaHls;
}

export type HlsManifestItems = [string, string]

export enum TypeReceiver {
  LIVE = 'live',
  ARCHIVE = 'archive'
}