import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AnonymousGuard, AuthGuard } from '@app/security';
import { QuicklinkModule } from 'ngx-quicklink';
import { LoginPageComponent } from '.';
import { AbonentPageComponent, AbonentsPageComponent, MailingNewComponent, MailingPageComponent, SignUpsPageComponent } from './abonents/components';
import { DashboardPageComponent } from './dashboard/components';
import { EntrancePageComponent, EntrancesPageComponent } from './entrances';
import { AdaptersPageComponent, BatchesPageComponent, CamerasPageComponent, ModelsPageComponent, NewAdaptersPageComponent, OrdersPageComponent, ShieldsPageComponent, ShieldTestsComponent } from './intercom/components';
import { PbxOnRdaListPageComponent } from './pbx-on-rda/components';
import { GatePageComponent, GatesPageComponent } from '@app/views/services/submodules';
import { SoftwareIntercomPageComponent, SoftwareIntercomsPageComponent } from '@app/views/services/submodules';
import { VideoSurveillanceListPageComponent, VideoSurveillancePageComponent } from '@app/views/services/submodules';
import { PaymentsPageComponent } from '@app/views/payments';
import {
  VideoManagerAddEditPageComponent,
  VideoManagerPageComponent, VideoManagerShowScreenPageComponent
} from '@app/views/services/submodules/video-manager';
import {
  VideoManagerPageActionsService
} from '@app/views/services/submodules/video-manager/services/video-manager-page-actions.service';
import {
  ApartmentVideoServicePageComponent,
  ApartmentVideoSurveillancePageComponent
} from '@app/views/services/submodules/apartment-video-surveillance/components';
import {
  ApartmentVideoSurveillanceByIdResolver
} from '@app/views/services/submodules/apartment-video-surveillance/services/apartment-video-surveillance-by-id.resolver';

@NgModule({
  imports: [
    QuicklinkModule,
    RouterModule.forRoot([
      {
        path: 'intercom',
        canActivate: [AuthGuard],
        children: [
          {
            path: 'adapters',
            component: AdaptersPageComponent,
            canActivate: [AuthGuard],
            data: { link: 'intercom/adapters', page: 'Adapters', name: 'adapters', menuBtnId: 'adapters' }
          },
          {
            path: 'new-adapters',
            component: NewAdaptersPageComponent,
            canActivate: [AuthGuard],
            data: { link: 'intercom/new-adapters', page: 'NewAdapters', name: 'new_adapters', menuBtnId: 'newAdapters' }
          },
          {
            path: 'batches',
            component: BatchesPageComponent,
            canActivate: [AuthGuard],
            data: { link: 'intercom/batches', page: 'Batches', name: 'batches', menuBtnId: 'batches' }
          },
          {
            path: 'shields',
            component: ShieldsPageComponent,
            canActivate: [AuthGuard],
            data: { link: 'intercom/shields', page: 'Shields', name: 'shields', menuBtnId: 'shields' }
          },
          {
            path: 'shield',
            children: [{
              path: ':id',
              component: ShieldTestsComponent
            }],
            canActivate: [AuthGuard],
            data: { link: 'intercom/shields/shield', page: 'Shield', name: 'shields', menuBtnId: 'shields', isBackBtnEnabled: true }
          },
          {
            path: 'models',
            component: ModelsPageComponent,
            canActivate: [AuthGuard],
            data: { link: 'intercom/models', page: 'Models', name: 'models', menuBtnId: 'models' }
          },
          {
            path: 'cameras',
            component: CamerasPageComponent,
            canActivate: [AuthGuard],
            data: { link: 'intercom/cameras', page: 'Cameras', name: 'cameras', menuBtnId: 'cameras' }
          },
          {
            path: 'orders',
            component: OrdersPageComponent,
            canActivate: [AuthGuard],
            data: { link: 'intercom/orders', page: 'Orders', name: 'orders', menuBtnId: 'orders' }
          },
          {
            path: 'entrances',
            redirectTo: '/entrances'
          },
          {
            path: 'entrance/:id',
            redirectTo: '/entrances/:id'
          }
        ]
      },
      {
        path: 'partners',
        loadChildren: () => import('./company/company.module').then(m => m.CompanyModule)
      },
      {
        path: 'abonents',
        canActivate: [AuthGuard],
        children: [
          {
            path: 'subscribers',
            component: AbonentsPageComponent,
            canActivate: [AuthGuard],
            data: { link: 'abonents/subscribers', page: 'Subscribers', name: 'subscribers', menuBtnId: 'abonents', }
          },
          {
            path: 'subscriber',
            children: [{
              path: ':id',
              component: AbonentPageComponent
            }],
            canActivate: [AuthGuard],
            data: { link: 'abonents/subscribers/subscriber', page: 'Subscriber', name: 'subscriber', menuBtnId: 'abonents', isBackBtnEnabled: true }
          },
          {
            path: 'sign_ups',
            component: SignUpsPageComponent,
            canActivate: [AuthGuard],
            data: { link: 'abonents/sign_ups', page: 'SignUps', name: 'sign_ups', menuBtnId: 'signUps' }
          }
        ]
      },
      {
        path: 'mailing',
        canActivate: [AuthGuard],
        children: [
          {
            path: 'all',
            component: MailingPageComponent,
            canActivate: [AuthGuard],
            data: { link: 'mailing/all', page: 'MailingAll', name: 'mailings', menuBtnId: 'mailing' },
          },
          {
            path: 'new',
            component: MailingNewComponent,
            canActivate: [AuthGuard],
            data: { link: 'mailing/new', page: 'MailingNew', name: 'new_mailing', menuBtnId: 'mailing', isBackBtnEnabled: true }
          },
          {
            path: ':id',
            component: MailingNewComponent,
            canActivate: [AuthGuard],
            data: { link: 'mailing/id', page: 'Mailing', name: 'mailing', menuBtnId: 'mailing', isBackBtnEnabled: true }
          }
        ]
      },
      {
        path: 'video/services',
        data: { link: 'services/video-surveillance', page: 'VideoSurveillance' },
        redirectTo: 'services/video-surveillance'
      },
      {
        path: 'video/service/:id',
        data: { link: 'services/video-surveillance/id', page: 'VideoSurveillance' },
        redirectTo: 'services/video-surveillance/:id'
      },
      {
        path: 'entrances',
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: EntrancesPageComponent,
            canActivate: [AuthGuard],
            data: { link: 'entrances', page: 'Entrances', name: 'entrances', menuBtnId: 'entrances' },
          },
          {
            path: ':id',
            component: EntrancePageComponent,
            canActivate: [AuthGuard],
            data: { link: 'entrances/id', page: 'Entrance', name: 'entrance', menuBtnId: 'entrances', isBackBtnEnabled: true }
          }
        ]
      },
      {
        path: 'personal_surveillance',
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: ApartmentVideoSurveillancePageComponent,
            canActivate: [AuthGuard],
            data: {
              link: 'personal_surveillance',
              page: 'ApartmentVideoSurveillance',
              name: 'personal_surveillance',
              menuBtnId: 'personal_surveillance'
            },
          },
          {
            path: ':id',
            component: ApartmentVideoServicePageComponent,
            resolve: { serviceInfo: ApartmentVideoSurveillanceByIdResolver },
            canActivate: [AuthGuard],
            data: {
              link: 'personal_surveillance/id',
              page: 'ApartmentVideoSurveillance',
              name: 'personal_surveillance',
              menuBtnId: 'personal_surveillance',
              isBackBtnEnabled: true 
            }
          }
        ]
      },
      {
        path: 'services',
        canActivate: [AuthGuard],
        children: [
          {
            path: 'gates',
            children: [
              {
                path: '',
                component: GatesPageComponent,
                canActivate: [AuthGuard],
                data: { link: 'services/gates', page: 'Gates', name: 'gates', menuBtnId: 'gates' },
              },
              {
                path: ':id',
                component: GatePageComponent,
                canActivate: [AuthGuard],
                data: { link: 'services/gates/id', page: 'Gates', name: 'gate', menuBtnId: 'gates', isBackBtnEnabled: true }
              }
            ]
          },
          {
            path: 'video-surveillance',
            children: [
              {
                path: '',
                component: VideoSurveillanceListPageComponent,
                canActivate: [AuthGuard],
                data: { link: 'services/video-surveillance', page: 'VideoSurveillance', name: 'video_surveillance', menuBtnId: 'video' },
              },
              {
                path: ':id',
                component: VideoSurveillancePageComponent,
                canActivate: [AuthGuard],
                data: { link: 'services/video-surveillance/id', page: 'VideoSurveillance', name: 'video_surveillance', menuBtnId: 'video', isBackBtnEnabled: true }
              }
            ]
          },
          {
            path: 'software-intercoms',
            children: [
              {
                path: '',
                component: SoftwareIntercomsPageComponent,
                canActivate: [AuthGuard],
                data: { link: 'services/software-intercoms', page: 'SoftwareIntercom', name: 'software_intercoms', menuBtnId: 'softwareIntercoms' },
              },
              {
                path: ':id',
                component: SoftwareIntercomPageComponent,
                canActivate: [AuthGuard],
                data: { link: 'services/software-intercoms/id', page: 'SoftwareIntercom', name: 'software_intercom', menuBtnId: 'softwareIntercoms', isBackBtnEnabled: true }
              }
            ]
          },
          {
            path: 'video-manager',
            children: [
              {
                path: '',
                component: VideoManagerPageComponent,
                canActivate: [AuthGuard],
                data: {
                  link: 'services/video-manager',
                  page: 'VideoManager',
                  name: 'video_manager',
                  menuBtnId: 'video-manager',
                  additionalActionsService: VideoManagerPageActionsService
                },
              },
              {
                path: 'add',
                component: VideoManagerAddEditPageComponent,
                canActivate: [AuthGuard],
                data: {
                  link: 'services/video-manager/add',
                  page: 'VideoManager',
                  name: 'video_manager_add',
                  menuBtnId: 'video-manager-add',
                  isBackBtnEnabled: true,
                  parentRoute: {
                    link: 'services/video-manager',
                    label: 'core.menu.button.to_screens_list',
                  },
                  add: true
                }
              },
              {
                path: 'edit/:id',
                component: VideoManagerAddEditPageComponent,
                canActivate: [AuthGuard],
                data: {
                  link: 'services/video-manager/edit',
                  page: 'VideoManager',
                  name: 'video_manager_edit',
                  menuBtnId: 'video-manager-edit',
                  isBackBtnEnabled: true,
                  parentRoute: {
                    link: 'services/video-manager',
                    label: 'core.menu.button.to_screens_list',
                  },
                  add: false
                }
              },
              {
                path: 'show/:id',
                component: VideoManagerShowScreenPageComponent,
                canActivate: [AuthGuard],
                data: {
                  link: 'services/video-manager/show',
                  page: 'VideoManager',
                  name: 'video_manager_show',
                  menuBtnId: 'video-manager-show',
                  fullScreen: true,
                  isBackBtnEnabled: true,
                  parentRoute: {
                    link: 'services/video-manager',
                    label: '',
                  },
                  add: false
                }
              }
            ]
          },
        ]
      },
      {
        path: 'login',
        component: LoginPageComponent,
        canActivate: [AnonymousGuard],
        data: { link: 'login', page: 'Login', name: 'login' }
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        component: DashboardPageComponent,
        data: { link: 'dashboard', page: 'Dashboard', name: 'dashboard', menuBtnId: 'dashboard' }
      },
      {
        path: 'pbx-on-rda',
        canActivate: [AuthGuard],
        component: PbxOnRdaListPageComponent,
        data: { link: 'pbx', page: 'Pbx', name: 'pbx_on_rda', menuBtnId: 'pbx' }
      },
      {
        path: 'payments',
        canActivate: [AuthGuard],
        component: PaymentsPageComponent,
        data: { link: 'payments', page: 'Payments', name: 'payments', menuBtnId: 'payments' }
      },
      {
        path: 'integrations',
        loadChildren: () => import('../views/integrations/integrations.module').then(m => m.IntegrationsModule),
        canActivate: [AuthGuard],
        data: {link: 'integrations', page: 'Integrations', name: 'integrations', menuBtnId: 'integrations'}
      },
      {
        path: 'support',
        loadChildren: () => import('../views/support-service/support-service.module')
          .then(m => m.SupportServiceModule),
        canActivate: [AuthGuard],
        data: { link: 'support'}
      },
      {
        path: 'reset-password',
        loadChildren: () => import('../shared/components/password/reset-password/reset-password.module')
          .then(m => m.ResetPasswordModule),
      },
      {
        path: '**',
        redirectTo: 'dashboard'
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard'
      }
    ],
      {})
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
  constructor(@Optional() @SkipSelf() parentModule: AppRoutingModule) {
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import AppRouting module in the AppModule only.`);
    }
  }
}
