import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {DialogService} from 'primeng/dynamicdialog';
import {of, Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {VideoType} from '@app/shared/containers/camera/camera-thumbnail/models/videoType';
import {ActivatedRoute, Router} from '@angular/router';
import {
  VideoManagerPageDataService
} from '@app/views/services/submodules/video-manager/services/video-manager-page-data.service';
import {VideoManagerN} from '@app/views/services/submodules/video-manager/models/view-manager';
import {
  VideoManagerAddCameraModalComponent
} from '@app/views/services/submodules/video-manager/components/video-manager-add-camera-modal/video-manager-add-camera-modal.component';
import {filter, mergeMap, takeUntil} from 'rxjs/operators';
import {cloneDeep} from 'lodash';
import {ConfirmationService, ConfirmEventType} from 'primeng/api';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {SnackbarService} from '@app/shared/components';
import AddCameraModalOutput = VideoManagerN.AddCameraModalOutput;

interface LayoutSelectorOptions {
  icon: string;
  value: VideoManagerN.ViewVariants;
}

interface LayoutSelectorForm {
  name: AbstractControl<string, string>;
  layout: AbstractControl<VideoManagerN.ViewVariants, VideoManagerN.ViewVariants>;
  layoutOriginal: AbstractControl<VideoManagerN.ViewVariants, VideoManagerN.ViewVariants>;
}

@Component({
  selector: 'app-video-manager-add-edit-page',
  templateUrl: './video-manager-add-edit-page.component.html',
  styleUrls: ['./video-manager-add-edit-page.component.scss', '../../styles/video-manager-shared.styles.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VideoManagerAddEditPageComponent implements OnInit, OnDestroy {
  protected readonly videoMode = VideoType;
  protected readonly ViewVariants = VideoManagerN.ViewVariants;
  protected readonly GetViewScheme = VideoManagerN.GetViewScheme;
  protected readonly GetMaxBlockByViewScheme = VideoManagerN.GetMaxBlockByViewScheme;
  public readonly defaultGridsCell: VideoManagerN.ScreenItem[] = this.getCamerasArray(VideoManagerN.ViewVariants.eightByEight);
  public readonly layoutOptions: LayoutSelectorOptions[] = [
    {icon: 'pi pi-user', value: VideoManagerN.ViewVariants.one},
    {icon: 'pi pi-user', value: VideoManagerN.ViewVariants.largeOneAndFourOnRightSide},
    {icon: 'pi pi-user-minus', value: VideoManagerN.ViewVariants.twoByTwo},
    {icon: 'pi pi-user-minus', value: VideoManagerN.ViewVariants.threeByThree},
    {icon: 'pi pi-users', value: VideoManagerN.ViewVariants.fourByFour},
    {icon: 'pi pi-verified', value: VideoManagerN.ViewVariants.fiveByFive},

    // Temporarily hiding modes larger than 5x5
    
    // {icon: 'pi pi-video', value: VideoManagerN.ViewVariants.sixBySix},
    // {icon: 'pi pi-vimeo', value: VideoManagerN.ViewVariants.sevenBySeven},
    // {icon: 'pi pi-vimeo', value: VideoManagerN.ViewVariants.eightByEight}
  ];

  public isLoaded = false;

  public editMode = false;

  public confirmationChangeLayout = true;
  public rememberChoice = {
    unselectCamera: false,
    layout: false
  };
  public formGroup: FormGroup;
  public screen: VideoManagerN.Screen;

  public get selectedView(): VideoManagerN.ViewVariants {
    return this.formGroup?.get('layout')?.value;
  }

  public get isNeedBackToShowScreen(): boolean {
    return Boolean(this.activatedRoute?.snapshot?.queryParams?.back);
  }

  public get simpleLayout(): boolean {
    return this.formGroup?.get('layout')?.value === VideoManagerN.ViewVariants.one;
  }

  public get isAllOk(): boolean {
    if (!this.formGroup || !this.screen) {
      return false;
    }
    const selectedCameras = this.screen?.cameras?.filter((c) => !!c?.camera)?.length;
    const layoutTitle = `${this.formGroup?.get('name')?.value}`.trim();

    return selectedCameras > 0 && !!layoutTitle;
  }

  private destroy = new Subject<void>();

  constructor(
    public videoManagerPageDataService: VideoManagerPageDataService,
    private dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private snackbarService: SnackbarService
  ) {
  }

  public ngOnInit(): void {
    this.videoManagerPageDataService.loaded
      .pipe(
        takeUntil(this.destroy),
        mergeMap((loaded) => {
          if (loaded) {
            return this.activatedRoute.data;
          }
          return of(null);
        })
      )
      .subscribe((data) => {
        this.isLoaded = false;

        const id = Number.parseInt(this.activatedRoute.snapshot.params['id']?.match(/([\d]+)/)[0], 10);
        const screen = this.videoManagerPageDataService.getScreenById(id);

        this.formGroup = new FormGroup<LayoutSelectorForm>({
          name: new FormControl<string>(screen?.name, [Validators.required]),
          layoutOriginal: new FormControl<VideoManagerN.ViewVariants>(screen?.layout || VideoManagerN.ViewVariants.one),
          layout: new FormControl<VideoManagerN.ViewVariants>(screen?.layout || VideoManagerN.ViewVariants.one),
        });

        if (!data?.add && !!screen) {
          this.screen = cloneDeep(screen);
          this.screen.cameras = this.getCamerasArray(VideoManagerN.ViewVariants.eightByEight)
          this.editMode = true;
        } else {
          this.screen = {
            id: Date.now(),
            name: '',
            layout: VideoManagerN.ViewVariants.one,
            cameras: this.getCamerasArray(VideoManagerN.ViewVariants.eightByEight)
          };
        }

        this.formGroup.get('layout')?.valueChanges.pipe(takeUntil(this.destroy)).subscribe(() => {
          this.screen.layout = this.formGroup.get('layout')?.value;
          this.confirmationChangeLayout = true;
        });

        this.isLoaded = true;
      });
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  public onOptionClick(e: { index: number; option: any, originalEvent: PointerEvent }): void {
    this.confirmationChangeLayout = true;
    this.formGroup.get('layout')?.setValue(e?.option?.value)
  }

  public saveLayout(): void {
    if (this.isAllOk) {
      this.screen.name = this.formGroup.get('name')?.value;
      this.showConfirmationDialog(
        this.translate.instant('video.manager.common.saving_screen'),
        `${this.translate.instant('video.manager.common.continue')}?`,
        () => {
          const layout = this.formGroup.get('layout')?.value
          const numberOfCameras = VideoManagerN.GetMaxBlockByViewScheme(layout);
          this.screen.cameras = this.screen.cameras.slice(0, numberOfCameras)

          if (this.editMode) {
            this.videoManagerPageDataService.editScreen(this.screen);
          } else {
            this.videoManagerPageDataService.createScreen(this.screen);
          }
          this.snackbarService.showMessage(this.translate.instant('video.manager.common.saved'), 'success');
          this.editMode && this.isNeedBackToShowScreen ?
            this.router.navigate(['/services/video-manager/show', this.screen.id]) :
            this.router.navigate(['/services/video-manager']);
        },
        (type) => {
        },
      );
    }
  }

  public drop(event: CdkDragDrop<VideoManagerN.ScreenItem[]>, target: VideoManagerN.ScreenItem): void {
    if (event.item.data.index === target.index) {
      return;
    }
    if (!!event.item.data.camera) {
      const draggedCamera = this.screen.cameras.find((c) => event.item.data.index === c.index);
      const targetOrig = cloneDeep(target);
      target.camera = draggedCamera.camera;
      target.cameraId = draggedCamera.cameraId;
      draggedCamera.cameraId = !!targetOrig?.cameraId ? targetOrig?.cameraId : null;
      draggedCamera.camera = !!targetOrig?.camera ? targetOrig?.camera : null;
      this.changeDetectorRef.markForCheck();
    }
  }

  public unselectCamera(cameraInGrid: VideoManagerN.ScreenItem): void {
    this.confirmationChangeLayout = false;
    const action = () => {
      cameraInGrid.camera = null;
      cameraInGrid.cameraId = null;
      this.changeDetectorRef.markForCheck();
    };
    if (!this.rememberChoice.unselectCamera) {
      this.showConfirmationDialog(
        this.translate.instant('video.manager.common.unselect_camera'),
        this.translate.instant('video.manager.common.are_you_sure'),
        action,
        (type) => {
          this.rememberChoice.unselectCamera = false;
        },
      );
    } else {
      action();
    }
  }

  public selectCamera(cameraInGrid: VideoManagerN.ScreenItem): void {
    this.dialogService.open(VideoManagerAddCameraModalComponent, {
      header: this.translate.instant('video.manager.common.selecting_camera'),
      height: '90%',
      width: '80%',
      draggable: true,
      maximizable: true,
      closeOnEscape: true,
      data: cameraInGrid
    }).onClose
      .pipe(takeUntil(this.destroy), filter((output: AddCameraModalOutput) => !!output?.selectedCamera))
      .subscribe((output: AddCameraModalOutput) => {
        cameraInGrid.camera = output?.selectedCamera;
        cameraInGrid.cameraId = output?.selectedCamera?.id;
        this.changeDetectorRef.markForCheck();
      });
  }

  private showConfirmationDialog(header: string, message: string, accept: () => void, reject: (type: ConfirmEventType) => void): void {
    this.confirmationService.confirm({
      header,
      message,
      icon: 'pi pi-info-circle',
      accept,
      reject,
      key: 'confDialog'
    });
  }

  private getCamerasArray(layout: VideoManagerN.ViewVariants): VideoManagerN.ScreenItem[] {
    const needCheckAndValidate = !!this.screen;
    const array: VideoManagerN.ScreenItem[] = [];
    const numberOfCameras = VideoManagerN.GetMaxBlockByViewScheme(layout);
    for (let i = 1; i <= numberOfCameras; i++) {
      if (needCheckAndValidate) {
        const camera = this.screen.cameras.find((c) => c.index === i);
        if (!camera) {
          array.push({index: i, cameraId: null, camera: null});
        } else {
          array.push(camera);
        }
      } else {
        array.push({index: i, cameraId: null, camera: null});
      }
    }
    return array;
  }
}
