import { Inject, Injectable, Injector } from "@angular/core";
import { PeerConnectionService } from "../peer-connection/peer-connection.service";
import { IVideoConfig, IVideoConfigHls, IVideoConfigWebRTC } from "../models/video.models";
import { HlsConnectionService } from "../hls-service/hls-connection.service";
import { MediaService } from "../media.service/media.service";
import { RdeaHls, VideoPlayerFragmentShortDate } from "@app/shared/components/video-player";
import { TimelineData } from "../models/timeline.model";
import { PlayerStateEnum } from "../models/state.model";
import { Option } from "../models/select.model";
import { Observable } from "rxjs";
import { ErrorData } from "hls.js";
import { RdvaPlaylistItem } from "@app/shared/entities/integrations";
import { HlsManifestItems } from "../models/hls.model";
import { HlsHelper } from "../helpers/hls-helper";
import { MessageServiceRegistry } from "../message-service/message-service-registry.service";
import { StoreRegistryService } from "../store/store-registry.service";
import { SignalType } from "../models/signal.model";
import { map, switchMap } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class RdVideoService {
  mediaStreamList$ = this.mediaService.mediaInfoList$;

  constructor(
    private mediaService: MediaService,
    private peerConnectionService: PeerConnectionService,
    private hlsConnectionService: HlsConnectionService,
    private storeRegistryService: StoreRegistryService,
    private messageServiceRegistry: MessageServiceRegistry
  ) { }

  initWebRTC(streamIds: number[], signalType: SignalType): void {
    this.peerConnectionService.createPeerConnection(streamIds, signalType);
  }

  addReceiverWebRTC(config: IVideoConfigWebRTC): void {
    this.peerConnectionService.addReceiver(config.instanceId, config.streamId);
  }

  addReceiverHls(config: IVideoConfigHls): void {
    this.hlsConnectionService.addReceiver(config);
  }

  updateCurrentPlaylist(storeId: string, value: RdvaPlaylistItem): void {
    this.storeRegistryService.getStore(storeId)
      .updateCurrentPlaylist(value);
  }

  updateCurrentEvent(storeId: string, option: Option<VideoPlayerFragmentShortDate>): void {
    this.storeRegistryService.getStore(storeId)
      .updateCurrentEvent(option);
    this.storeRegistryService.getStore(storeId)
      .fireNewPlayerCurrentTime(option.value.startPlayerTime);
  }

  onHlsConnectionErrorType$(storeId: string): Observable<ErrorData> {
    return this.storeRegistryService.selectStore(storeId)
      .pipe(
        switchMap(value => value.onHlsConnectionErrorType$())
      )
  }

  onPlayListItems$(storeId: string): Observable<RdvaPlaylistItem[]> {
    return this.storeRegistryService.selectStore(storeId)
      .pipe(
        switchMap(value => value.selectPlayListItems$())
      )
  }

  onCurrentPlaylist$(storeId: string): Observable<RdvaPlaylistItem> {
    return this.storeRegistryService.selectStore(storeId)
      .pipe(
        switchMap(value => value.selectCurrentPlaylist$())
      )
  }

  onTimelineData$(storeId: string): Observable<TimelineData> {
    return this.storeRegistryService.selectStore(storeId)
      .pipe(
        switchMap(value => value.selectTimelineData$())
      )
  }

  updateEventOptions(storeId: string, value: Option<VideoPlayerFragmentShortDate>[]): void {
    this.storeRegistryService.getStore(storeId).updateEventOptions(value)
  }

  createOption<T>(value: T, label: string): Option<T> {
    return {
      value,
      label
    }
  }

  getPlaylistHlsManifestItems(storeId: string): HlsManifestItems {
    return this.storeRegistryService.getStore(storeId).getPlaylistHlsManifestItems();
  }

  destroyWebRTCStream(id: string): void {
    this.peerConnectionService.destroyPeerConnection(id);
  }

  destroyHlsStream(id: string): void {
    this.hlsConnectionService.destroyHls(id);
  }

  destroy(): void {
    this.storeRegistryService.destroy()
    this.peerConnectionService.destroy();
    this.hlsConnectionService.destroy();
    this.mediaService.destroy();
  }
}
