import { Component, EventEmitter, Input, Output } from "@angular/core";
import { RdVideoService } from "../../service/video.service";
import { DecorateUntilDestroy, takeUntilDestroyed } from "@app/shared/rxjs/operator/take-until-destroyed";
import { StoreService } from "../../store/store.service";

@DecorateUntilDestroy()
@Component({
  selector: 'rd-video-event-control',
  templateUrl: './event-control.html',
  styleUrls: ['./event-control.scss']
})
export class RdVideoEventControlComponent {
  @Output() clickPrevEvent = new EventEmitter();
  @Output() clickNextEvent = new EventEmitter();

  constructor(private storeService: StoreService) { }

  clickPrev(): void {
    const eventsOptions = this.storeService.getEventOptions()
    if (!eventsOptions || !eventsOptions.length) {
      return;
    }

    const currentSelectEvent = this.storeService.getCurrentEvent()
    if (!currentSelectEvent) {
      this.storeService.updateCurrentEvent(eventsOptions[0])
      this.storeService.fireNewPlayerCurrentTime(currentSelectEvent.value.startPlayerTime);
      return;
    }

    const currentSelectIndex = eventsOptions
      .findIndex(event => event.value.startPlayerTime === currentSelectEvent.value.startPlayerTime);

    const prevEvent = eventsOptions[currentSelectIndex === 0 ? 0 : currentSelectIndex - 1]
    this.storeService.updateCurrentEvent(prevEvent)

    this.storeService.fireNewPlayerCurrentTime(prevEvent.value.startPlayerTime);
    this.clickPrevEvent.emit();
  }

  clickNext(): void {
    const eventsOptions = this.storeService.getEventOptions()
    if (!eventsOptions || !eventsOptions.length) {
      return;
    }

    const currentSelectEvent = this.storeService.getCurrentEvent()
    if (!currentSelectEvent) {
      this.storeService.updateCurrentEvent(eventsOptions[0])
      this.storeService.fireNewPlayerCurrentTime(currentSelectEvent.value.startPlayerTime);
      return;
    }

    const currentSelectIndex = eventsOptions
      .findIndex(event => event.value.startPlayerTime === currentSelectEvent.value.startPlayerTime);

    const lastIndex = eventsOptions.length - 1;
    const nextEvent = eventsOptions[currentSelectIndex === lastIndex
      ? lastIndex
      : currentSelectIndex + 1]
    this.storeService.updateCurrentEvent(nextEvent)

    this.storeService.fireNewPlayerCurrentTime(nextEvent.value.startPlayerTime);
    this.clickNextEvent.emit();
  }
}
