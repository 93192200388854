import { Component, Input } from '@angular/core';
import { StoreService } from '../../store/store.service';

@Component({
  selector: 'rd-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class RdLoaderComponent {
  isLoading$ = this.storeService.selectIsLoadData();

  constructor(private storeService: StoreService){}
}