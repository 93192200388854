import {HttpErrorResponse} from '@angular/common/http';

export const parseError = (error: HttpErrorResponse): string => {
  if (!error) {
    return null;
  }

  if (error?.status === 0) {
    return 'Не удалось соединиться с сервером. Попробуйте повторить действие';
  }

  if (error.error?.['error'] === 'invalid_grant' || error.error?.['error'] === 'unauthorized') {
    return 'Неверный логин или пароль';
  }


  if (error instanceof HttpErrorResponse) {
    if (error.error) {
      //временное решение
      //надо пересмотреть логику работы handleError, с бэка error может прийти и string, а не только ErrorEvent | ErrorResponse
      const monitoringChatIdRefExp = /Telegram notification chat with id \S+ is already exists/;
      if (monitoringChatIdRefExp.test(error.error)) {
        return 'чат с таким Telegram ID уже существует';
      }

      return error.error['errorLocalized'] || 'Неизвестная ошибка';
    }

    return error.statusText;
  }

  return error;
};
